const WorkspaceIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2236 8.05276L11.5 6.69091V3.50001C11.5 3.40715 11.4742 3.31613 11.4254 3.23713C11.3765 3.15814 11.3067 3.0943 11.2236 3.05276L8.22365 1.55276C8.1542 1.51806 8.07763 1.5 8 1.5C7.92237 1.5 7.8458 1.51806 7.77635 1.55276L4.77635 3.05276C4.6933 3.0943 4.62346 3.15814 4.57465 3.23713C4.52584 3.31613 4.49999 3.40715 4.5 3.50001V6.69091L1.77635 8.05276C1.6933 8.0943 1.62346 8.15814 1.57465 8.23713C1.52584 8.31613 1.49999 8.40715 1.5 8.50001V12C1.49999 12.0929 1.52584 12.1839 1.57465 12.2629C1.62346 12.3419 1.6933 12.4057 1.77635 12.4473L4.77635 13.9473C4.8458 13.982 4.92237 14 5 14C5.07763 14 5.1542 13.982 5.22365 13.9473L8 12.5591L10.7764 13.9473C10.8458 13.982 10.9224 14 11 14C11.0776 14 11.1542 13.982 11.2236 13.9473L14.2236 12.4473C14.3067 12.4057 14.3765 12.3419 14.4254 12.2629C14.4742 12.1839 14.5 12.0929 14.5 12V8.50001C14.5 8.40715 14.4742 8.31613 14.4254 8.23713C14.3765 8.15814 14.3067 8.0943 14.2236 8.05276ZM10.5 6.69091L8.5 7.69091V5.30911L10.5 4.30911V6.69091ZM8 2.55911L9.88185 3.50001L8 4.44091L6.11815 3.50001L8 2.55911ZM5.5 4.30911L7.5 5.30911V7.69091L5.5 6.69091V4.30911ZM4.5 12.6909L2.5 11.6909V9.30911L4.5 10.3091V12.6909ZM5 9.44091L3.11815 8.50001L5 7.55911L6.88185 8.50001L5 9.44091ZM5.5 10.3091L7.5 9.30911V11.6909L5.5 12.6909V10.3091ZM10.5 12.6909L8.5 11.6909V9.30911L10.5 10.3091V12.6909ZM11 9.44091L9.11815 8.50001L11 7.55911L12.8819 8.50001L11 9.44091ZM13.5 11.6909L11.5 12.6909V10.3091L13.5 9.30911V11.6909Z"
      />
    </svg>
  );
};

export default WorkspaceIcon;
