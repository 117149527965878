import React from "react";
import MemoryIcon from "../components/icons/memory-icon";

const usePrompts = () => {
  const [prompts, setPrompts] = React.useState([]);
  const [activePrompt, setActivePrompt] = React.useState(null);

  React.useEffect(() => {
    setPrompts([
      {
        label: "Create memory",
        question: "Begin new memory",
        icon: <MemoryIcon />,
      },
    ]);
  }, []);

  const clearSelectedPrompt = () => {
    setActivePrompt(null);
  };

  return {
    prompts,
    activePrompt,
    setActivePrompt,
    clearSelectedPrompt,
  };
};

export default usePrompts;
