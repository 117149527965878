import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

export const ApiEndpointsContext = createContext({
	user_id: '',
	api_key: '',
	chat_access_token: '',
	auth_frontend_token: '',
});

const ApiEndpointsProvider = ({ value, children }) => {
	const {
		data: { user_id, api_key, chat_access_token, auth_frontend_token },
	} = value || {};
	// const [loading, setLoading] = useState(true);

	useEffect(() => {
		const setAuthParams = () => {
			localStorage.setItem('user_id', user_id);

			localStorage.setItem('api_key', api_key);

			localStorage.setItem('chat_access_token', chat_access_token);

			localStorage.setItem('auth_frontend_token', auth_frontend_token);

			// setLoading(false);
		};

		setAuthParams();
	}, [user_id, api_key, chat_access_token, auth_frontend_token]);

	// if (loading) return <LoaderWrapper loading={loading} />;

	return (
		<ApiEndpointsContext.Provider value={value}>
			{children}
		</ApiEndpointsContext.Provider>
	);
};

export const useApiEndpointsContext = () => {
	return useContext(ApiEndpointsContext);
};

ApiEndpointsProvider.propTypes = {
	value: PropTypes.shape({}),
	children: PropTypes.node,
};

export default ApiEndpointsProvider;
