export const API_URL_MEMORY = "https://memory-api.yourstruly.love";
export const API_URL_NEW = "https://newstaging.yourstruly.love";

export const MODALS_ID = {
  VIEW_IMAGE_MODAL: "VIEW_IMAGE_MODAL",
};

export const EVENTS = {
    MEMORIES: 'Memories',
    KNOWLEDGE: 'Knowledge',
    ASSETS: 'Assets',
    WISHES: 'Wishes',
    FUNERAL: 'Funeral',
};

export const MEDIA_TYPES = {
    VIDEO: 'Video',
    AUDIO: 'Audio',
    IMAGES: 'Images',
    DOCUMENTS: 'Documents',
};

export const LAYOUT_TYPES = {
    ROW: 'ROW',
    COLUMN: "COLUMN"
};
