import { useCallback } from "react";
import { useModalsContext } from "../context/modal-provider.jsx";

function useToggleModal(modalID, argsProps = {}) {
  const { addModal, closeModal, modals } = useModalsContext();
  const modalState = modals?.get(modalID);

  const toggle = useCallback(
    (args = {}) => {
      if (modalState) return closeModal({ id: modalID });
      addModal({
        id: modalID,
        args: {
          ...args,
          ...argsProps,
        },
      });
    },
    [modalState, closeModal, modalID, addModal, argsProps]
  );

  const onClose = () => closeModal({ id: modalID });
  const onOpen = (args) => addModal({ id: modalID, args });

  return { toggle, onClose, onOpen, isOpened: Boolean(modalState), modalState };
}

export default useToggleModal;
