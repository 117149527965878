import React from 'react';
import CreateMemoryIcon from '../components/icons/create-memory-icon';
import CreateKnowledgeIcon from '../components/icons/create-knowledge-icon';
import CreateEventIcon from '../components/icons/create-event-icon';
import CreateAssetIcon from '../components/icons/create-asset-icon';
import CreateAddToWishlistIcon from '../components/icons/create-add-to-wishlist';
import CreateDayOfFuneralIcon from '../components/icons/create-day-of-funeral';
import CreatePostScriptIcon from '../components/icons/create-post-script';

const usePrompts = () => {
	const [prompts, setPrompts] = React.useState([]);
	const [activePrompt, setActivePrompt] = React.useState(null);

	React.useEffect(() => {
		setPrompts([
			{
				label: 'Create Memory',
				question: 'Begin new memory',
				icon: <CreateMemoryIcon />,
			},
			{
				label: 'Create Event',
				question: 'Begin new event',
				icon: <CreateEventIcon />,
			},
			{
				label: 'Create Knowledge',
				question: 'Begin new knowledge',
				icon: <CreateKnowledgeIcon />,
			},
			{
				label: 'Create Asset',
				question: 'Begin new asset',
				icon: <CreateAssetIcon />,
			},
			{
				label: 'Add to Wishlist',
				question: 'Begin new bucket list',
				icon: <CreateAddToWishlistIcon />,
			},
			{
				label: 'Day of/Funeral',
				question: 'Begin new Day of Funeral',
				icon: <CreateDayOfFuneralIcon />,
			},
			{
				label: 'Create Postscript',
				question: 'Begin new Postscript',
				icon: <CreatePostScriptIcon />,
			},
		]);
	}, []);

	const clearSelectedPrompt = () => {
		setActivePrompt(null);
	};

	return {
		prompts,
		activePrompt,
		setActivePrompt,
		clearSelectedPrompt,
	};
};

export default usePrompts;
