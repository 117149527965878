import { useRef, useState } from "react";
import { generateId } from "../utils/fns.js";
import axios from "axios";
import toast from "react-hot-toast";
import { useApiEndpointsContext } from "../context/api-endpoints-provider";
import { API_URL_MEMORY } from "../utils/const";

const useUploadFile = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const cancelTokenSource = useRef(null);

  const { data: { user_id, api_key }} = useApiEndpointsContext();

  const STATUS = {
    PENDING: "pending",
    UPLOADED: "uploaded",
    FAILED: "failed",
  };

  const handleUpload = async (file, onSuccess = () => null) => {
    try {
      const fileName = generateId("media") + file.name;
      const newF = new File([file], fileName, { type: file.type });

      setProgress(0);

      setLoading(true);

      const presignedUrl = await getPresignedUrl(fileName);

      if (presignedUrl) {
        const { data: mediaInfo, status: mediaInfoStatus } = await crudMediaInfo({
          fileName,
          userId: user_id,
        });

        const mediaId = mediaInfo.id;

        if (mediaInfoStatus === 200) {
          const { status: uploadStatus } = await uploadToPresignedUrl({
            presignedUrl,
            file: newF,
            fileName,
            mediaId,
            onSuccess,
          });

          if (uploadStatus === 200) {
            const { status: updateMediaInfoStatus } = await crudMediaInfo({
              id: mediaId,
              status: STATUS.UPLOADED,
              userId: user_id,
            });

            if (updateMediaInfoStatus === 200) {
              setLoading(false);

              toast.success("Successfully uploaded!");

              return { id: mediaId, url: URL.createObjectURL(file), presignedUrl };
            }
          }
        }
      }
    } catch (e) {
      console.log("upload error: ", e);
      setProgress(0);
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  const getPresignedUrl = async (nameUniq) => {
    const data = {
      fileNames: [nameUniq],
    };

    const presignedUrlsReq = await axios({
      method: "POST",
      url: API_URL_MEMORY + "/api/media/presigned-url",
      headers: {
        Authorization: "Bearer " + api_key,
      },
      data,
    });

    return presignedUrlsReq.data.urls[0];
  };

  const uploadToPresignedUrl = async (props) => {
    const { presignedUrl, file, mediaId, onSuccess = () => null, onFailed = () => null } = props || {};

    setProgress(0);

    cancelTokenSource.current = axios.CancelToken.source();

    const uploadResponse = await axios
      .put(presignedUrl, file, {
        headers: {
          "Content-Type": "application/*",
        },
        cancelToken: cancelTokenSource.current.token,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader("x-decompressed-content-length");

          if (totalLength !== null) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);

            // setProgress(prev => ({...prev, [file.name]: progress}))
            setProgress(progress);
          }
        },
      })
      .then((data) => {
        if (data?.status === 200 || data.status === 201) onSuccess(mediaId);
        else onFailed();

        return data;
      })
      .catch(async (error) => {
        if (axios.isCancel(error)) {
          console.log("Upload canceled", error.message);

          await crudMediaInfo({
            id: mediaId,
            status: STATUS.FAILED,
            userId: user_id,
          });
        } else {
          onFailed();
          toast.error(error?.message);
        }

        setProgress(null);
      });

    return uploadResponse;
  };

  const crudMediaInfo = async (props) => {
    const {
      fileName: cdnKey,
      id = "",
      status = STATUS.PENDING,
      userId,
      category = "memoriesMedia",
      mediaType = "image",
      title = "memory",
      summary = "unknown",
      method = "PUT",
      triggerBy = 'ai'
    } = props || {};

    const isPending = status === "pending";

    const data = isPending
      ? {
          userId,
          cdnKey,
          category,
          mediaType,
          title,
          summary,
          status,
          triggerBy
        }
      : {
          status,
        };

    const res = await axios({
      method: isPending ? "POST" : method,
      url: `https://memory-api.yourstruly.love/api/media/${id}`,
      headers: {
        Authorization: "Bearer " + api_key,
        "X-YT-UserId": userId,
      },
      data,
    }).then((res) => {
      return {
        data: res?.data?.mediaFile,
        status: res.status,
      };
    });

    return res;
  };

  const cancelUpload = async () => {
    if (cancelTokenSource.current) {
      await cancelTokenSource.current.cancel("Upload canceled by the user.");

      cancelTokenSource.current = null;
    }
  };

  return {
    progress,
    loading,
    handleUpload,
    cancelUpload,
  };
};

export default useUploadFile;
