import { useClickOutside } from "./click-outside.jsx";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const CustomModal = (props) => {
  if (!props.isOpened) return;

  return createPortal(<ModalBody {...props} />, document.body);
};

const ModalBody = ({ width, onClose, children, className, style }) => {
  const { clickOutsideRef } = useClickOutside({ onClickOutside: onClose });

  return (
    <div className="custom-modal-root">
      <div className={`custom-modal ${className}`} style={{ width, ...style }} ref={clickOutsideRef}>
        {children}
      </div>
    </div>
  );
};

ModalBody.propTypes = {
  width: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default CustomModal;
