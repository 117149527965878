const AdditionalInfoIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.7685 5.88L12.12 4.2315C11.9713 4.08326 11.7699 4.00001 11.56 4.00001C11.3501 4.00001 11.1487 4.08326 11 4.2315L4 11.2337V14H6.7663L13.7685 7C13.9167 6.85133 14 6.64995 14 6.44C14 6.23005 13.9167 6.02867 13.7685 5.88ZM6.3521 13H5V11.6479L9.72045 6.92745L11.0725 8.27955L6.3521 13ZM11.7795 7.5725L10.4275 6.22045L11.5611 5.08685L12.9132 6.43895L11.7795 7.5725Z"
				fill="#565967"
			/>
			<path d="M4.5 7.5H5.5V4H7V3H3V4H4.5V7.5Z" fill="#565967" />
			<path
				d="M3 9H1V1H9V3H10V1C10 0.734784 9.89464 0.48043 9.70711 0.292893C9.51957 0.105357 9.26522 0 9 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H3V9Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default AdditionalInfoIcon;
