import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const useClickOutside = ({ onClickOutside }) => {
  const clickOutsideRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  return { clickOutsideRef };
};

function ClickOutside({ onClickOutside, children }) {
  const { clickOutsideRef } = useClickOutside({ onClickOutside });

  return <div ref={clickOutsideRef}>{children}</div>;
}

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.node,
};

export default ClickOutside;
