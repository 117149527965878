const MediaIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 7.5V11.5L10 9.5L6.5 7.5Z" />
      <path
        d="M13 14H3C2.73487 13.9997 2.48068 13.8943 2.2932 13.7068C2.10572 13.5193 2.00028 13.2651 2 13V6C2.00028 5.73487 2.10572 5.48068 2.2932 5.2932C2.48068 5.10572 2.73487 5.00028 3 5H13C13.2651 5.00028 13.5193 5.10572 13.7068 5.2932C13.8943 5.48068 13.9997 5.73487 14 6V13C13.9997 13.2651 13.8943 13.5193 13.7068 13.7068C13.5193 13.8943 13.2651 13.9997 13 14ZM3 6V13H13V6H3Z"
      />
      <path d="M13 3H3V4H13V3Z" />
      <path d="M12 1H4V2H12V1Z" />
    </svg>
  );
};

export default MediaIcon;
