import React from 'react';
import usePrompts from '../hooks/usePrompts.jsx';
import useMessages from '../hooks/useMessages.js';

import PropTypes from 'prop-types';

export const ChatContext = React.createContext(null);

export const ChatProvider = ({ children }) => {
	const { messages, sendMessage, eventData, isLoading, sendSaveMemory } =
		useMessages();
	const { prompts, activePrompt, clearSelectedPrompt, setActivePrompt } =
		usePrompts();

	const onPromptSelect = (selectedPrompt) => {
		setActivePrompt(selectedPrompt);
		sendMessage(selectedPrompt.question);
	};

	return (
		<ChatContext.Provider
			value={{
				prompts,
				activePrompt,
				clearSelectedPrompt,
				onPromptSelect,
				messages,
				sendMessage,
				eventData,
				isLoading,
				sendSaveMemory,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

export function useChatContext() {
	const {
		prompts,
		activePrompt,
		clearSelectedPrompt,
		onPromptSelect,
		messages,
		sendMessage,
		eventData,
		isLoading,
		sendSaveMemory,
	} = React.useContext(ChatContext);

	return {
		prompts,
		activePrompt,
		clearSelectedPrompt,
		onPromptSelect,
		messages,
		sendMessage,
		eventData,
		isLoading,
		sendSaveMemory,
	};
}

ChatProvider.propTypes = {
	children: PropTypes.node,
};
