import useToggleModal from "../../hooks/useToggleModal.jsx";
import { MODALS_ID } from "../../utils/const.js";
import CustomModal from "../common/custom-modal.jsx";

const ViewImageModal = () => {
  const { onClose, isOpened, modalState } = useToggleModal(MODALS_ID.VIEW_IMAGE_MODAL);

  if (!isOpened && !!modalState?.args?.src) return;

  return (
    <CustomModal isOpened={isOpened} onClose={onClose} width={"532"} className="!p-0 !b-0">
      <img src={modalState?.args?.src} className="object-contain self-stretch aspect-square" />
    </CustomModal>
  );
};

export default ViewImageModal;
