const MemoryIcon = (props) => {
    return (
        <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.5 8.5H4.5V9.5H5.5V8.5Z" />
            <path d="M5.5 10.5H4.5V11.5H5.5V10.5Z" />
            <path d="M8.5 4.835V1H10V0H0V1H1.5V4.835C1.5 5.05137 1.57018 5.2619 1.7 5.435L2.875 7L1.7 8.565C1.57018 8.7381 1.5 8.94863 1.5 9.165V13H0V14H10V13H8.5V9.165C8.5 8.94863 8.42982 8.7381 8.3 8.565L7.125 7L8.3 5.435C8.42982 5.2619 8.5 5.05137 8.5 4.835ZM7.5 1V4.5H2.5V1H7.5ZM7.5 9.165V13H2.5V9.165L4.125 7L3 5.5H7L5.875 7L7.5 9.165Z" />
        </svg>
    )
}

export default MemoryIcon
