const CreateAddToWishlistIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.49981 13.207L6.99981 10.7065L7.70632 9.99995L9.49981 11.793L13.2923 7.99995L13.9998 8.70745L9.49981 13.207Z"
                fill="#565967"
            />
            <path
                d="M4.99981 6.79495L3.70482 5.49995L2.99982 6.20495L4.99981 8.20495L8.49981 4.70495L7.79482 3.99995L4.99981 6.79495Z"
                fill="#565967"
            />
            <path
                d="M4.90946 11.9011C3.7439 11.6847 2.66864 11.1277 1.81954 10.3004C0.970449 9.47313 0.385637 8.41272 0.139008 7.25319C-0.10762 6.09365 -0.00499535 4.88703 0.433914 3.78581C0.872823 2.68458 1.62832 1.73817 2.60493 1.06617C3.58154 0.394177 4.73543 0.0267561 5.92079 0.0103407C7.10615 -0.00607465 8.26977 0.329252 9.26461 0.973946C10.2595 1.61864 11.0409 2.54377 11.5101 3.63242C11.9793 4.72107 12.1153 5.92439 11.9009 7.0903L10.9175 6.9096C11.0973 5.93713 10.9847 4.93317 10.594 4.02468C10.2032 3.1162 9.55183 2.34398 8.72221 1.80567C7.89259 1.26736 6.92198 0.987141 5.93312 1.00044C4.94425 1.01374 3.98152 1.31995 3.16668 1.88038C2.35184 2.4408 1.72147 3.23025 1.35528 4.14892C0.98909 5.06758 0.903527 6.0742 1.10941 7.04149C1.31529 8.00878 1.80337 8.89331 2.51194 9.58322C3.2205 10.2731 4.11773 10.7374 5.09017 10.9175L4.90946 11.9011Z"
                fill="#565967"
            />
        </svg>
    );
};

export default CreateAddToWishlistIcon;
