const CreateKnowledgeIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 11.5H9.5C9.10217 11.5 8.72064 11.658 8.43934 11.9393C8.15804 12.2206 8 12.6022 8 13V14H9V13C9 12.8674 9.05268 12.7402 9.14645 12.6464C9.24021 12.5527 9.36739 12.5 9.5 12.5H12.5C12.6326 12.5 12.7598 12.5527 12.8536 12.6464C12.9473 12.7402 13 12.8674 13 13V14H14V13C14 12.6022 13.842 12.2206 13.5607 11.9393C13.2794 11.658 12.8978 11.5 12.5 11.5Z"
                fill="#565967"
            />
            <path
                d="M9 9C9 9.39556 9.1173 9.78224 9.33706 10.1111C9.55682 10.44 9.86918 10.6964 10.2346 10.8478C10.6001 10.9991 11.0022 11.0387 11.3902 10.9616C11.7781 10.8844 12.1345 10.6939 12.4142 10.4142C12.6939 10.1345 12.8844 9.77814 12.9616 9.39018C13.0387 9.00222 12.9991 8.60009 12.8478 8.23463C12.6964 7.86918 12.44 7.55682 12.1111 7.33706C11.7822 7.1173 11.3956 7 11 7C10.4696 7 9.96086 7.21071 9.58579 7.58579C9.21071 7.96086 9 8.46957 9 9ZM12 9C12 9.19778 11.9414 9.39112 11.8315 9.55557C11.7216 9.72002 11.5654 9.84819 11.3827 9.92388C11.2 9.99957 10.9989 10.0194 10.8049 9.98079C10.6109 9.9422 10.4327 9.84696 10.2929 9.70711C10.153 9.56726 10.0578 9.38907 10.0192 9.19509C9.98063 9.00111 10.0004 8.80004 10.0761 8.61732C10.1518 8.43459 10.28 8.27841 10.4444 8.16853C10.6089 8.05865 10.8022 8 11 8C11.2652 8 11.5196 8.10536 11.7071 8.29289C11.8946 8.48043 12 8.73478 12 9Z"
                fill="#565967"
            />
            <path
                d="M2 9.5V9H1V9.5C1 10.4283 1.36875 11.3185 2.02513 11.9749C2.6815 12.6313 3.57174 13 4.5 13H6V12H4.5C3.83696 12 3.20107 11.7366 2.73223 11.2678C2.26339 10.7989 2 10.163 2 9.5Z"
                fill="#565967"
            />
            <path d="M12 4H8.5V5H12V4Z" fill="#565967" />
            <path d="M13.5 2H8.5V3H13.5V2Z" fill="#565967" />
            <path d="M13.5 8.3599e-07H8.5V1H13.5V8.3599e-07Z" fill="#565967" />
            <path
                d="M4.5 4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6V7H1V6C1 5.86739 1.05268 5.74022 1.14645 5.64645C1.24021 5.55268 1.36739 5.5 1.5 5.5H4.5C4.63261 5.5 4.75979 5.55268 4.85355 5.64645C4.94732 5.74022 5 5.86739 5 6V7H6V6C6 5.60218 5.84196 5.22064 5.56066 4.93934C5.27936 4.65804 4.89782 4.5 4.5 4.5Z"
                fill="#565967"
            />
            <path
                d="M3 4C3.39556 4 3.78224 3.8827 4.11114 3.66294C4.44004 3.44318 4.69638 3.13082 4.84776 2.76537C4.99913 2.39992 5.03874 1.99778 4.96157 1.60982C4.8844 1.22186 4.69392 0.865492 4.41421 0.585787C4.13451 0.306082 3.77814 0.115601 3.39018 0.0384303C3.00222 -0.0387401 2.60009 0.000866562 2.23463 0.152242C1.86918 0.303617 1.55682 0.559962 1.33706 0.88886C1.1173 1.21776 1 1.60444 1 2C1 2.53043 1.21071 3.03914 1.58579 3.41421C1.96086 3.78929 2.46957 4 3 4ZM3 1C3.19778 1 3.39112 1.05865 3.55557 1.16853C3.72002 1.27841 3.84819 1.43459 3.92388 1.61732C3.99957 1.80004 4.01937 2.00111 3.98079 2.19509C3.9422 2.38907 3.84696 2.56726 3.70711 2.70711C3.56725 2.84696 3.38907 2.9422 3.19509 2.98079C3.00111 3.01937 2.80004 2.99957 2.61732 2.92388C2.43459 2.84819 2.27841 2.72002 2.16853 2.55557C2.05865 2.39112 2 2.19778 2 2C2 1.73478 2.10536 1.48043 2.29289 1.29289C2.48043 1.10536 2.73478 1 3 1Z"
                fill="#565967"
            />
        </svg>
    );
};

export default CreateKnowledgeIcon;
