import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import ModalsProvider from "./chat/context/modal-provider.jsx";
import ApiEndpointsProvider from "./chat/context/api-endpoints-provider.jsx";
import { ChatProvider } from "./chat/context/chat-provider.jsx";
import App from "./chat";

// styles
import "./chat/styles/App.scss";

const Root = (props) => {
  const { data, config } = props;
  return (
    <ApiEndpointsProvider
      value={{
        data,
        config
      }}
    >
      <ChatProvider>
        <ModalsProvider>
          <App />
          <Toaster
            position="bottom-left"
            toastOptions={{
              duration: 6000,
              success: {
                style: {
                  background: "#daffda",
                },
              },
              error: {
                style: {
                  background: "#ffc2c2",
                  color: "black",
                },
              },
            }}
          />
        </ModalsProvider>
      </ChatProvider>
    </ApiEndpointsProvider>
  );
};

window.Ai_Chat = {
  init: function (id, { data, config }) {
    const root = document.getElementById(id);
    if (root) createRoot(root).render(<Root data={data} config={config} />);
  },
};
