const BankNameIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 0H7C6.73486 0.000264738 6.48066 0.105707 6.29319 0.293186C6.10571 0.480665 6.00026 0.734865 6 1V6H1C0.734865 6.00026 0.480665 6.10571 0.293186 6.29319C0.105707 6.48066 0.000264738 6.73486 0 7V14H14V1C13.9997 0.734877 13.8942 0.4807 13.7068 0.293229C13.5193 0.105759 13.2651 0.000304367 13 0ZM3.5 13V9.5H5.5V13H3.5ZM13 13H6.5V9C6.5 8.86739 6.44732 8.74021 6.35355 8.64645C6.25979 8.55268 6.13261 8.5 6 8.5H3C2.86739 8.5 2.74021 8.55268 2.64645 8.64645C2.55268 8.74021 2.5 8.86739 2.5 9V13H1V7H7V1H13V13Z"
				fill="#565967"
			/>
			<path d="M9 3H8V4H9V3Z" fill="#565967" />
			<path d="M12 3H11V4H12V3Z" fill="#565967" />
			<path d="M9 6H8V7H9V6Z" fill="#565967" />
			<path d="M12 6H11V7H12V6Z" fill="#565967" />
			<path d="M9 8.9998H8V9.9998H9V8.9998Z" fill="#565967" />
			<path d="M12 8.9998H11V9.9998H12V8.9998Z" fill="#565967" />
		</svg>
	);
};

export default BankNameIcon;
