import React from 'react';

const SkeletonLoader = () => {
  return (
    <div role="status" className="h-full w-full flex flex-col items-center first-letter:p-4 space-y-6 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
      <div className="flex items-center justify-between w-[520px] max-w-full  p-3">
          <div>
            <div className="flex gap-1 items-center">
              <svg className="w-6 h-6 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
              </svg>
              <div className="h-2.5 bg-gray-400 rounded-full w-24"></div>
            </div>
            <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
            <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
            <div className="w-32 h-2 bg-gray-300 rounded-full mt-2"></div>
          </div>
      </div>
      <div className="flex items-center justify-between w-[520px] max-w-full  bg-gray-200 rounded-br-2xl rounded-bl-2xl rounded-tr-2xl p-3">
          <div>
            <div className="flex gap-1 items-center">
              <svg className="w-6 h-6 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
              </svg>
              <div className="h-2.5 bg-gray-400 rounded-full w-24"></div>
            </div>
              <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
              <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
              <div className="w-32 h-2 bg-gray-300 rounded-full mt-2"></div>
          </div>
      </div>
      <div className="flex items-center justify-between w-[520px] max-w-full  p-3">
          <div>
            <div className="flex gap-1 items-center">
              <svg className="w-6 h-6 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
              </svg>
              <div className="h-2.5 bg-gray-400 rounded-full w-24"></div>
            </div>
              <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
              <div className="w-64 h-2 bg-gray-300 rounded-full mt-2"></div>
              <div className="w-32 h-2 bg-gray-300 rounded-full mt-2"></div>
          </div>
      </div>
      <div className="flex items-center justify-between w-[520px] max-w-full gap-3 !mt-auto p-3 bg-gray-200 rounded-md">
        <div className="h-6 w-6 bg-gray-300 rounded-md"></div>
        <div className="h-6 flex-1"></div>
        <div className="h-6 w-6 bg-gray-300 rounded-md"></div>
        <div className="h-6 w-12 bg-gray-300 rounded-md"></div>
        <div className="h-6 w-6 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
