const UploadDocumentsIcon = () => {
	return (
		<svg
			width="12"
			height="14"
			viewBox="0 0 12 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 8L1.705 8.705L5.5 4.915V14H6.5V4.915L10.295 8.705L11 8L6 3L1 8Z"
				fill="#565967"
			/>
			<path
				d="M1 3V1H11V3H12V1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V3H1Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default UploadDocumentsIcon;
