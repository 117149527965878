const ModelBrandIcon = () => {
	return (
		<svg
			width="12"
			height="13"
			viewBox="0 0 12 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M4.5 6.5V10.5L8 8.5L4.5 6.5Z" fill="#565967" />
			<path
				d="M11 13H1C0.734869 12.9997 0.480676 12.8943 0.2932 12.7068C0.105724 12.5193 0.00027795 12.2651 0 12V5C0.00027795 4.73487 0.105724 4.48068 0.2932 4.2932C0.480676 4.10572 0.734869 4.00028 1 4H11C11.2651 4.00028 11.5193 4.10572 11.7068 4.2932C11.8943 4.48068 11.9997 4.73487 12 5V12C11.9997 12.2651 11.8943 12.5193 11.7068 12.7068C11.5193 12.8943 11.2651 12.9997 11 13ZM1 5V12H11V5H1Z"
				fill="#565967"
			/>
			<path d="M11 2H1V3H11V2Z" fill="#565967" />
			<path d="M10 0H2V1H10V0Z" fill="#565967" />
		</svg>
	);
};

export default ModelBrandIcon;
