const AddressIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.295 5.655L14 4.95L9 0L8.345 0.71L8.935 1.3L3.19 6.16L2.33 5.305L1.625 6L4.455 8.84L0 13.29L0.705 14L5.16 9.545L8 12.375L8.695 11.665L7.84 10.81L12.7 5.065L13.295 5.655ZM7.13 10.1L3.9 6.87L9.645 2L12 4.355L7.13 10.1Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default AddressIcon;
