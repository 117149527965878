import axios from 'axios'

import { resolveError } from '../utils/fns.js'

const instanceChat = axios.create({
    baseURL: 'https://chat.activezero.org/api/',
});

const instanceMemory = axios.create({
    baseURL: 'https://memory-api.yourstruly.love/api/'
});

instanceChat.interceptors.request.use((config) => {
    const token = localStorage.getItem("chat_access_token");
    const userId = localStorage.getItem("user_id");

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (userId) {
        config.headers['X-YT-UserId'] = userId;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

instanceMemory.interceptors.request.use((config) => {
    const token = localStorage.getItem("api_key");
    const userId = localStorage.getItem("user_id");

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (userId) {
        config.headers['X-YT-UserId'] = userId;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

const getInstance = (useChatApi) => {
    return useChatApi ? instanceChat : instanceMemory;
};

export const getData = (endpoint, params, useChatApi = false) =>
    getInstance(useChatApi)
        .get(endpoint, { params })
        .then(res => res.data)
        .catch(error => resolveError(error));

export const postData = (endpoint, data, headers = {}, useChatApi = false) =>
    getInstance(useChatApi)
        .post(endpoint, data, { headers })
        .then(res => res)
        .catch(error => resolveError(error));

export const putData = (endpoint, data, useChatApi = false) =>
    getInstance(useChatApi)
        .put(endpoint, data)
        .then(res => res)
        .catch(error => resolveError(error));

export const deleteData = (endpoint, useChatApi = false) =>
    getInstance(useChatApi)
        .delete(endpoint)
        .then(res => res)
        .catch(error => resolveError(error));