export function generateId(prefix = '') {
    return `${prefix}-${Math.random().toString(36).substr(2, 9)}`
}

export function to(promise, ...args) {
    return promise(...args)
        .then((data) => {
            return { err: null, data }
        })
        .catch((err) => ({ err, data: null }))
}

export const resolveError = (error) => {
    if (error.response) {
        const { data } = error.response
        if (typeof data === 'string') {
            return Promise.reject(data)
        } else if (data?.message || data?.error) {
            return Promise.reject(data?.message || data?.error)
        }
    }
    return Promise.reject('Something went wrong! Please try again later!')
}

export const getPageId = () => Number(window.location.pathname.split('/').pop())

export const formatMessageTimestamp = (timestamp) => {
    const currentTime = new Date()
    const pastTime = new Date(timestamp)
    const differenceInMilliseconds = currentTime - pastTime

    const seconds = Math.floor(differenceInMilliseconds / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)

    if (months > 0) {
        return `${months} month(s) ago`
    } else if (days > 0) {
        return `${days} day(s) ago`
    } else if (hours > 0) {
        return `${hours} hour(s) ago`
    } else if (minutes > 0) {
        return `${minutes} minute(s) ago`
    } else {
        return 'Just now'
    }
}
