const InheritorsIcon = () => {
	return (
		<svg
			width="14"
			height="15"
			viewBox="0 0 14 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.00025 14.5H2.00025C1.73527 14.4992 1.48137 14.3936 1.294 14.2062C1.10663 14.0189 1.00103 13.765 1.00025 13.5V10C0.73527 9.99922 0.481368 9.89362 0.294 9.70625C0.106632 9.51888 0.00102565 9.26498 0.000247404 9V6C-0.00338122 5.80202 0.0329383 5.60534 0.107036 5.42171C0.181133 5.23808 0.291488 5.07128 0.431505 4.93126C0.571522 4.79124 0.738329 4.68089 0.921957 4.60679C1.10559 4.53269 1.30227 4.49637 1.50025 4.5H4.50025C4.69823 4.49637 4.89491 4.53269 5.07854 4.60679C5.26217 4.68089 5.42897 4.79124 5.56899 4.93126C5.70901 5.07128 5.81936 5.23808 5.89346 5.42171C5.96756 5.60534 6.00388 5.80202 6.00025 6V9C5.99947 9.26498 5.89386 9.51888 5.70649 9.70625C5.51913 9.89362 5.26522 9.99922 5.00025 10V13.5C4.99947 13.765 4.89386 14.0189 4.70649 14.2062C4.51913 14.3936 4.26522 14.4992 4.00025 14.5ZM1.50025 5.5C1.43354 5.49598 1.36673 5.50615 1.30424 5.52985C1.24176 5.55356 1.18501 5.59025 1.13775 5.63751C1.09049 5.68476 1.0538 5.74151 1.0301 5.804C1.0064 5.86648 0.996221 5.93329 1.00025 6V9H2.00025V13.5H4.00025V9H5.00025V6C5.00427 5.93329 4.9941 5.86648 4.9704 5.804C4.94669 5.74151 4.91 5.68476 4.86274 5.63751C4.81549 5.59025 4.75874 5.55356 4.69625 5.52985C4.63376 5.50615 4.56696 5.49598 4.50025 5.5H1.50025Z"
				fill="#565967"
			/>
			<path
				d="M3.00025 4C2.60468 4 2.21801 3.8827 1.88911 3.66294C1.56021 3.44318 1.30386 3.13082 1.15249 2.76537C1.00111 2.39992 0.961507 1.99778 1.03868 1.60982C1.11585 1.22186 1.30633 0.865492 1.58603 0.585787C1.86574 0.306082 2.22211 0.115601 2.61007 0.0384303C2.99803 -0.0387401 3.40016 0.000866562 3.76561 0.152242C4.13107 0.303617 4.44342 0.559962 4.66319 0.88886C4.88295 1.21776 5.00025 1.60444 5.00025 2C4.99965 2.53025 4.78875 3.03861 4.4138 3.41356C4.03886 3.7885 3.5305 3.99941 3.00025 4ZM3.00025 1C2.80247 1 2.60913 1.05865 2.44468 1.16853C2.28023 1.27841 2.15206 1.43459 2.07637 1.61732C2.00068 1.80004 1.98088 2.00111 2.01946 2.19509C2.05805 2.38907 2.15329 2.56726 2.29314 2.70711C2.43299 2.84696 2.61118 2.9422 2.80516 2.98079C2.99914 3.01937 3.2002 2.99957 3.38293 2.92388C3.56566 2.84819 3.72184 2.72002 3.83172 2.55557C3.9416 2.39112 4.00025 2.19778 4.00025 2C3.99998 1.73487 3.89454 1.48067 3.70706 1.29319C3.51958 1.10571 3.26538 1.00027 3.00025 1Z"
				fill="#565967"
			/>
			<path
				d="M13.3834 1.1279C13.0073 0.745785 12.4997 0.521473 11.964 0.50069C11.4283 0.479906 10.9048 0.664218 10.5002 1.01605C10.0957 0.664218 9.57223 0.479906 9.03651 0.50069C8.50079 0.521473 7.99316 0.745785 7.6171 1.1279C7.2217 1.53153 7.00025 2.07405 7.00025 2.63908C7.00025 3.2041 7.2217 3.74662 7.6171 4.15025L10.4992 7.07L10.5002 7.0688L10.5012 7.06975L13.3834 4.15025C13.7788 3.74662 14.0002 3.2041 14.0002 2.63908C14.0002 2.07405 13.7788 1.53153 13.3834 1.1279ZM12.6712 3.4474L10.5012 5.64605L10.5002 5.645L10.4992 5.646L8.32925 3.44735C8.11747 3.23155 7.99883 2.94126 7.99883 2.6389C7.99883 2.33654 8.11747 2.04626 8.32925 1.83045C8.54054 1.62505 8.8236 1.51014 9.11827 1.51014C9.41295 1.51014 9.69601 1.62505 9.9073 1.83045L10.4978 2.43415L10.5002 2.4317L10.5027 2.4342L11.0932 1.8305C11.3045 1.6251 11.5875 1.51019 11.8822 1.51019C12.1769 1.51019 12.46 1.6251 12.6712 1.8305C12.883 2.04631 13.0017 2.33659 13.0017 2.63895C13.0017 2.94131 12.883 3.2316 12.6712 3.4474Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default InheritorsIcon;
