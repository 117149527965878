import $ from 'jquery';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import Swiper from 'swiper';
Alpine.plugin(persist);
Alpine.plugin(focus);
import 'swiper/css';
import 'swiper/css/pagination';

import { Navigation, Autoplay, Pagination } from "swiper/modules";
Swiper.use([Autoplay, Pagination]);

window.jQuery = window.$ = $;
window.Alpine = Alpine
window.Swiper = Swiper;
window.Pagination = Pagination;
Alpine.start();