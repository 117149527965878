const EstateTypeIcon = () => {
	return (
		<svg
			width="16"
			height="13"
			viewBox="0 0 16 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.30615 0.106872C8.2174 0.0376156 8.10805 0 7.99547 0C7.8829 0 7.77355 0.0376156 7.6848 0.106872L0.5 5.70967L1.12135 6.49552L2 5.81042V12C2.00054 12.265 2.10607 12.5191 2.29349 12.7065C2.48091 12.8939 2.73495 12.9994 3 13H13C13.2651 12.9995 13.5191 12.894 13.7066 12.7065C13.894 12.5191 13.9995 12.265 14 12V5.81497L14.8786 6.49997L15.5 5.71407L8.30615 0.106872ZM9 12H7V7.99997H9V12ZM10 12V7.99997C9.9997 7.73485 9.89424 7.48067 9.70677 7.2932C9.5193 7.10573 9.26512 7.00028 9 6.99997H7C6.73486 7.00024 6.48066 7.10568 6.29319 7.29316C6.10571 7.48064 6.00026 7.73484 6 7.99997V12H3V5.03072L8 1.13572L13 5.03597V12H10Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default EstateTypeIcon;
