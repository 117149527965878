const ShareIcon = () => {
	return (
		<svg
			width="16"
			height="14"
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.5 11.5V6.91405L0.70705 8.70705L0 8L3 5L6 8L5.29295 8.70705L3.5 6.91405V11.5H9.5V12.5H3.5C3.23488 12.4997 2.98071 12.3942 2.79324 12.2068C2.60578 12.0193 2.50032 11.7651 2.5 11.5Z"
				fill="#565967"
			/>
			<path
				d="M12 10H14C14.2651 10.0003 14.5193 10.1057 14.7068 10.2932C14.8943 10.4807 14.9997 10.7349 15 11V13C14.9997 13.2651 14.8943 13.5193 14.7068 13.7068C14.5193 13.8943 14.2651 13.9997 14 14H12C11.7349 13.9997 11.4807 13.8943 11.2932 13.7068C11.1057 13.5193 11.0003 13.2651 11 13V11C11.0003 10.7349 11.1057 10.4807 11.2932 10.2932C11.4807 10.1057 11.7349 10.0003 12 10ZM14 13V11H11.9993L12 13H14Z"
				fill="#565967"
			/>
			<path
				d="M13.5 2V6.58595L15.2929 4.79295L16 5.5L13 8.5L10 5.5L10.7071 4.79295L12.5 6.58595V2H6.5V1H12.5C12.7651 1.00032 13.0193 1.10578 13.2068 1.29324C13.3942 1.48071 13.4997 1.73488 13.5 2Z"
				fill="#565967"
			/>
			<path d="M4 2H1V3H4V2Z" fill="#565967" />
			<path d="M5 0H1V1H5V0Z" fill="#565967" />
		</svg>
	);
};

export default ShareIcon;
