const OtherPeopleIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 14H13V11.5C12.9992 10.8372 12.7356 10.2017 12.2669 9.73307C11.7983 9.26439 11.1628 9.00075 10.5 9V8C11.4279 8.00103 12.3176 8.37011 12.9737 9.02627C13.6299 9.68242 13.999 10.5721 14 11.5V14Z"
				fill="#565967"
			/>
			<path
				d="M10 14H9V11.5C8.99922 10.8372 8.73558 10.2018 8.2669 9.7331C7.79823 9.26442 7.1628 9.00078 6.5 9H3.5C2.8372 9.00078 2.20177 9.26442 1.7331 9.7331C1.26442 10.2018 1.00078 10.8372 1 11.5V14H0V11.5C0.00108509 10.5721 0.370183 9.68247 1.02632 9.02632C1.68247 8.37018 2.57208 8.00109 3.5 8H6.5C7.42792 8.00109 8.31753 8.37018 8.97368 9.02632C9.62982 9.68247 9.99891 10.5721 10 11.5V14Z"
				fill="#565967"
			/>
			<path
				d="M9 0V1C9.66304 1 10.2989 1.26339 10.7678 1.73223C11.2366 2.20107 11.5 2.83696 11.5 3.5C11.5 4.16304 11.2366 4.79893 10.7678 5.26777C10.2989 5.73661 9.66304 6 9 6V7C9.92826 7 10.8185 6.63125 11.4749 5.97487C12.1313 5.3185 12.5 4.42826 12.5 3.5C12.5 2.57174 12.1313 1.6815 11.4749 1.02513C10.8185 0.368749 9.92826 0 9 0Z"
				fill="#565967"
			/>
			<path
				d="M5 1C5.49445 1 5.9778 1.14662 6.38893 1.42133C6.80005 1.69603 7.12048 2.08648 7.3097 2.54329C7.49892 3.00011 7.54843 3.50277 7.45196 3.98773C7.3555 4.47268 7.1174 4.91814 6.76777 5.26777C6.41814 5.6174 5.97268 5.8555 5.48773 5.95196C5.00277 6.04843 4.50011 5.99892 4.04329 5.8097C3.58648 5.62048 3.19603 5.30005 2.92133 4.88893C2.64662 4.4778 2.5 3.99445 2.5 3.5C2.5 2.83696 2.76339 2.20107 3.23223 1.73223C3.70107 1.26339 4.33696 1 5 1ZM5 0C4.30777 0 3.63108 0.205271 3.0555 0.589856C2.47993 0.974441 2.03133 1.52107 1.76642 2.16061C1.50151 2.80015 1.4322 3.50388 1.56725 4.18282C1.7023 4.86175 2.03564 5.48539 2.52513 5.97487C3.01461 6.46436 3.63825 6.7977 4.31718 6.93275C4.99612 7.0678 5.69985 6.99849 6.33939 6.73358C6.97893 6.46867 7.52556 6.02007 7.91014 5.4445C8.29473 4.86892 8.5 4.19223 8.5 3.5C8.5 2.57174 8.13125 1.6815 7.47487 1.02513C6.8185 0.368749 5.92826 0 5 0Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default OtherPeopleIcon;
