const CreatePostScriptIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 8.5C6.42247 8.50048 5.85382 8.35786 5.34487 8.08487C4.83593 7.81189 4.40256 7.41705 4.0835 6.93565L4.9165 6.38195C5.14459 6.72574 5.4542 7.00774 5.81774 7.20282C6.18127 7.3979 6.58743 7.49999 7 7.49999C7.41257 7.49999 7.81873 7.3979 8.18226 7.20282C8.5458 7.00774 8.85541 6.72574 9.0835 6.38195L9.9165 6.93565C9.59744 7.41705 9.16407 7.81189 8.65513 8.08487C8.14618 8.35786 7.57753 8.50048 7 8.5Z"
                fill="#565967"
            />
            <path
                d="M9 3C8.80222 3 8.60888 3.05865 8.44443 3.16853C8.27998 3.27841 8.15181 3.43459 8.07612 3.61732C8.00043 3.80004 7.98063 4.00111 8.01921 4.19509C8.0578 4.38907 8.15304 4.56725 8.29289 4.70711C8.43275 4.84696 8.61093 4.9422 8.80491 4.98079C8.99889 5.01937 9.19996 4.99957 9.38268 4.92388C9.56541 4.84819 9.72159 4.72002 9.83147 4.55557C9.94135 4.39112 10 4.19778 10 4C10.0013 3.86832 9.97632 3.7377 9.92652 3.61579C9.87672 3.49388 9.80311 3.38312 9.71 3.29C9.61688 3.19689 9.50612 3.12328 9.38421 3.07348C9.2623 3.02368 9.13168 2.9987 9 3Z"
                fill="#565967"
            />
            <path
                d="M5 3C4.80222 3 4.60888 3.05865 4.44443 3.16853C4.27998 3.27841 4.15181 3.43459 4.07612 3.61732C4.00043 3.80004 3.98063 4.00111 4.01921 4.19509C4.0578 4.38907 4.15304 4.56725 4.29289 4.70711C4.43275 4.84696 4.61093 4.9422 4.80491 4.98079C4.99889 5.01937 5.19996 4.99957 5.38268 4.92388C5.56541 4.84819 5.72159 4.72002 5.83147 4.55557C5.94135 4.39112 6 4.19778 6 4C6.0013 3.86832 5.97632 3.7377 5.92652 3.61579C5.87672 3.49388 5.80311 3.38312 5.71 3.29C5.61688 3.19689 5.50612 3.12328 5.38421 3.07348C5.2623 3.02368 5.13168 2.9987 5 3Z"
                fill="#565967"
            />
            <path
                d="M7.8679 14L7 13.5L9 10H12C12.1314 10.0002 12.2615 9.97451 12.3829 9.92434C12.5044 9.87416 12.6147 9.80051 12.7076 9.70761C12.8005 9.6147 12.8742 9.50437 12.9243 9.38295C12.9745 9.26152 13.0002 9.13138 13 9V2C13.0002 1.86862 12.9745 1.73848 12.9243 1.61705C12.8742 1.49563 12.8005 1.3853 12.7076 1.29239C12.6147 1.19949 12.5044 1.12584 12.3829 1.07566C12.2615 1.02549 12.1314 0.999776 12 1H2C1.86862 0.999776 1.73848 1.02549 1.61705 1.07566C1.49563 1.12584 1.3853 1.19949 1.29239 1.29239C1.19949 1.3853 1.12584 1.49563 1.07566 1.61705C1.02549 1.73848 0.999776 1.86862 1 2V9C0.999776 9.13138 1.02549 9.26152 1.07566 9.38295C1.12584 9.50437 1.19949 9.6147 1.29239 9.70761C1.3853 9.80051 1.49563 9.87416 1.61705 9.92434C1.73848 9.97451 1.86862 10.0002 2 10H6.5V11H2C1.73734 11 1.47725 10.9483 1.23458 10.8479C0.991907 10.7474 0.77141 10.6 0.585684 10.4143C0.399958 10.2286 0.252641 10.0081 0.152147 9.76542C0.0516543 9.52275 -4.58869e-05 9.26266 9.00623e-08 9V2C-7.87452e-05 1.73733 0.051599 1.47723 0.152081 1.23454C0.252562 0.991853 0.399878 0.771344 0.585611 0.585611C0.771344 0.399878 0.991853 0.252562 1.23454 0.152081C1.47723 0.051599 1.73733 -7.87452e-05 2 9.00623e-08H12C12.2627 -7.87452e-05 12.5228 0.051599 12.7655 0.152081C13.0081 0.252562 13.2287 0.399878 13.4144 0.585611C13.6001 0.771344 13.7474 0.991853 13.8479 1.23454C13.9484 1.47723 14.0001 1.73733 14 2V9C14 9.26266 13.9483 9.52275 13.8479 9.76542C13.7474 10.0081 13.6 10.2286 13.4143 10.4143C13.2286 10.6 13.0081 10.7474 12.7654 10.8479C12.5227 10.9483 12.2627 11 12 11H9.5823L7.8679 14Z"
                fill="#565967"
            />
        </svg>
    );
};

export default CreatePostScriptIcon;
