const CreateAssetIcon = () => {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.25 12C8.90388 12 8.56554 11.8974 8.27775 11.7051C7.98996 11.5128 7.76566 11.2395 7.63321 10.9197C7.50076 10.5999 7.4661 10.2481 7.53362 9.90859C7.60115 9.56912 7.76782 9.2573 8.01256 9.01256C8.2573 8.76782 8.56912 8.60115 8.90859 8.53362C9.24806 8.4661 9.59992 8.50076 9.91969 8.63321C10.2395 8.76566 10.5128 8.98997 10.7051 9.27775C10.8974 9.56554 11 9.90388 11 10.25C10.9995 10.714 10.8149 11.1588 10.4868 11.4868C10.1588 11.8149 9.71396 11.9995 9.25 12ZM9.25 9.5C9.10166 9.5 8.95666 9.54399 8.83332 9.6264C8.70998 9.70881 8.61386 9.82594 8.55709 9.96299C8.50032 10.1 8.48547 10.2508 8.51441 10.3963C8.54335 10.5418 8.61478 10.6754 8.71967 10.7803C8.82456 10.8852 8.9582 10.9566 9.10368 10.9856C9.24917 11.0145 9.39997 10.9997 9.53701 10.9429C9.67406 10.8861 9.79119 10.79 9.8736 10.6667C9.95601 10.5433 10 10.3983 10 10.25C9.99977 10.0512 9.92068 9.86052 9.78008 9.71992C9.63948 9.57931 9.44884 9.50022 9.25 9.5Z"
                fill="#565967"
            />
            <path
                d="M9.25 14.5C8.40943 14.5 7.58773 14.2507 6.88882 13.7837C6.18992 13.3167 5.64518 12.653 5.32351 11.8764C5.00184 11.0998 4.91767 10.2453 5.08166 9.42087C5.24565 8.59645 5.65042 7.83917 6.24479 7.2448C6.83917 6.65042 7.59645 6.24565 8.42086 6.08166C9.24528 5.91768 10.0998 6.00184 10.8764 6.32351C11.653 6.64518 12.3167 7.18992 12.7837 7.88883C13.2507 8.58774 13.5 9.40943 13.5 10.25C13.4987 11.3768 13.0505 12.457 12.2537 13.2537C11.457 14.0505 10.3768 14.4987 9.25 14.5ZM9.25 7C8.60721 7 7.97885 7.19061 7.4444 7.54772C6.90994 7.90484 6.49337 8.41242 6.24739 9.00628C6.0014 9.60014 5.93704 10.2536 6.06245 10.884C6.18785 11.5145 6.49738 12.0936 6.9519 12.5481C7.40642 13.0026 7.98552 13.3122 8.61595 13.4376C9.24639 13.563 9.89986 13.4986 10.4937 13.2526C11.0876 13.0066 11.5952 12.5901 11.9523 12.0556C12.3094 11.5211 12.5 10.8928 12.5 10.25C12.499 9.38835 12.1563 8.56227 11.547 7.95298C10.9377 7.3437 10.1117 7.00098 9.25 7Z"
                fill="#565967"
            />
            <path
                d="M10.5 1.5H9V1C8.99924 0.735019 8.89363 0.481109 8.70626 0.293738C8.51889 0.106368 8.26498 0.000765122 8 0H4C3.73502 0.000765122 3.48111 0.106368 3.29374 0.293738C3.10637 0.481109 3.00077 0.735019 3 1V1.5H1.5C1.23502 1.50077 0.981109 1.60637 0.793738 1.79374C0.606368 1.98111 0.500765 2.23502 0.5 2.5V13C0.500765 13.265 0.606368 13.5189 0.793738 13.7063C0.981109 13.8936 1.23502 13.9992 1.5 14H4V13H1.5V2.5H3V4H9V2.5H10.5V5H11.5V2.5C11.4992 2.23502 11.3936 1.98111 11.2063 1.79374C11.0189 1.60637 10.765 1.50077 10.5 1.5ZM8 3H4V1H8V3Z"
                fill="#565967"
            />
        </svg>
    );
};

export default CreateAssetIcon;
