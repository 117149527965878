import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import ModalsProvider from './chat/context/modal-provider.jsx';
import ApiEndpointsProvider from './chat/context/api-endpoints-provider.jsx';
import { ChatProvider } from './chat/context/chat-provider.jsx';
import App from './chat';

// styles
import './chat/styles/App.scss';

const Root = (props) => {
	const { data, config } = props;
	const [authFrontEndToken, setAuthFrontEndToken] = useState(null);

	async function fetchTokenFrontEnd() {
		try {
			const response = await fetch(
				'https://chat.activezero.org/api/v1/auth?sub=frontend'
			);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const data = await response.json();
			return data.token;
		} catch (error) {
			console.error('Ошибка при получении токена:', error);
			return null;
		}
	}

	useEffect(() => {
		const getFrontToken = async () => {
			const token = await fetchTokenFrontEnd();
			setAuthFrontEndToken(token);
		};

		getFrontToken();
	}, []);

	if (authFrontEndToken === null) {
		return <div>Loading...</div>;
	}

	// console.log(authFrontEndToken, 'main token');

	return (
		<ApiEndpointsProvider
			value={{
				data: { ...data, auth_frontend_token: authFrontEndToken },
				config,
			}}
		>
			<ChatProvider>
				<ModalsProvider>
					<App />
					<Toaster
						position="bottom-left"
						toastOptions={{
							duration: 6000,
							success: {
								style: {
									background: '#daffda',
								},
							},
							error: {
								style: {
									background: '#ffc2c2',
									color: 'black',
								},
							},
						}}
					/>
				</ModalsProvider>
			</ChatProvider>
		</ApiEndpointsProvider>
	);
};

window.Ai_Chat = {
	init: function (id, { data, config }) {
		const root = document.getElementById(id);
		if (root) createRoot(root).render(<Root data={data} config={config} />);
	},
};
