const CreateDayOfFuneralIcon = () => {
    return (
        <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.5 14H4.5C4.23487 13.9997 3.98068 13.8943 3.7932 13.7068C3.60572 13.5193 3.50028 13.2651 3.5 13V6H1C0.734869 5.99972 0.480676 5.89428 0.2932 5.7068C0.105724 5.51932 0.00027795 5.26513 0 5V4C0.00027795 3.73487 0.105724 3.48068 0.2932 3.2932C0.480676 3.10572 0.734869 3.00028 1 3H3.5V1C3.50028 0.734869 3.60572 0.480676 3.7932 0.2932C3.98068 0.105724 4.23487 0.00027795 4.5 0H5.5C5.76513 0.00027795 6.01932 0.105724 6.2068 0.2932C6.39428 0.480676 6.49972 0.734869 6.5 1V3H9C9.26513 3.00028 9.51932 3.10572 9.7068 3.2932C9.89428 3.48068 9.99972 3.73487 10 4V5C9.99972 5.26513 9.89428 5.51932 9.7068 5.7068C9.51932 5.89428 9.26513 5.99972 9 6H6.5V13C6.49972 13.2651 6.39428 13.5193 6.2068 13.7068C6.01932 13.8943 5.76513 13.9997 5.5 14ZM1 4V5H4.5V13H5.5V5H9V4H5.5V1H4.5V4H1Z"
                fill="#565967"
            />
        </svg>
    );
};

export default CreateDayOfFuneralIcon;
