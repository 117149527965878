import { useReducer } from "react";
import { to } from "../utils/fns.js";
import toast from "react-hot-toast";

const initialState = {
  data: null,
  error: null,
  loading: false,
  finished: false,
};

const fetchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT":
      return { data: null, loading: true, error: null, finished: false };
    case "SUCCESS":
      return { ...state, data: action.payload, loading: false, finished: true };
    case "ERROR":
      return { ...state, error: action.payload, loading: false, finished: true };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const useFetchResult = (promise, args = {}, showError = true) => {
  const [state, dispatch] = useReducer(fetchResultReducer, initialState);

  const onInit = () => dispatch({ type: "INIT" });
  const onSuccess = (payload) => dispatch({ type: "SUCCESS", payload });
  const onError = (payload) => dispatch({ type: "ERROR", payload });
  const onReset = () => dispatch({ type: "RESET" });

  const fetchData = async (customArgs = {}) => {
    onInit();

    const { err, data } = await to(promise, { ...args, ...customArgs });

    if (err) {
      if (showError) toast.error(err);

      dispatch({ type: "ERROR", payload: err });

      return null;
    } else {
      if (data) {
        dispatch({ type: "SUCCESS", payload: data });

        // dispatcher &&
        //   typeof dispatcher === 'function' &&
        //   dispatcher({ type: 'SUCCESS', data })
      }
    }

    return data;
  };

  return { fetchData, onError, onInit, onSuccess, state, onReset };
};
