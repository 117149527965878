import React from "react";
import { useFetchResult } from "./useFetchResult.js";
import { getData } from "../api/index.js";
import { formatMessageTimestamp, generateId } from "../utils/fns.js";
import { useApiEndpointsContext } from "../context/api-endpoints-provider.jsx";
import useUploadFile from './useUploadFile.jsx';

const useMessages = () => {
  const { handleUpload } = useUploadFile();
  const { data: { user_id }} = useApiEndpointsContext();
  const { fetchData: getChatHistory } = useFetchResult(() => getData(`chat-history/chat-history?user_id=${user_id}`, null, true));
  const [isLoading, setIsLoading] = React.useState(true);

  const mapMedia = (mediaUrls) => mediaUrls.map((fileSrc) => ({
    id: generateId(),
    src: fileSrc
  }));

  React.useEffect(() => {
    (async () => {
      setIsLoading(true)
      try {
        let res = await getChatHistory();
        if (res?.length) {
          res = res?.map((item) => {
            item.content = item?.text_content;
            if (item.timestamp) item.timestamp = formatMessageTimestamp(item.timestamp);
            if (item.media) item.media_files = mapMedia(item.media)

            return item;
          });

          setMessages(res);

          const creatingContext = res?.[res.length - 1]?.now_creating;

          if (creatingContext) {
            if (creatingContext.media_files?.length) {
              creatingContext.media_files = mapMedia(creatingContext.media_files)
            }

            if (res?.[res.length - 1]?.status_tag) {
              creatingContext.status_tag = res?.[res.length - 1]?.status_tag;
            }

            setEventData(creatingContext);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false)
      }
    })();
  }, []);

  const [eventData, setEventData] = React.useState(null);
  const [messages, setMessages] = React.useState(null);
  const [socket, setSocket] = React.useState(null);

  const connectWebSocket = React.useCallback(() => {
    console.log("Connecting to WebSocket...");
    const socketInstance = new WebSocket('wss://chat.activezero.org/api/v1/');

    socketInstance.onopen = () => {
      console.log("WebSocket connected");
      const connectionMessage = {
        content: { user_id },
        type: "connection",
      };
      socketInstance.send(JSON.stringify(connectionMessage));
    };

    socketInstance.onmessage = (event) => {
      try {
        const response = JSON.parse(event.data);
        if (response?.content) {
          setMessages((prevMessages) => {
            const meesageToDisplay = {
              sent_by: "AI",
              content: response.content,
              timestamp: formatMessageTimestamp(new Date().toISOString()),
            }

            if (Array.isArray(prevMessages)) {
              return [
                ...prevMessages,
                meesageToDisplay
              ]
            }
            return [meesageToDisplay];
          });
        }

        const creatingContext = response?.now_creating;

          if (creatingContext) {
            if (creatingContext.media_files?.length) {
              creatingContext.media_files = mapMedia(creatingContext.media_files)
            }
            if (response?.status_tag) {
              creatingContext.status_tag = response?.status_tag;
            }
            setEventData(creatingContext);
          }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    socketInstance.onclose = () => {
      console.log("WebSocket disconnected");
    };

    socketInstance.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(socketInstance);

    return () => socketInstance.close();
  }, [user_id]);

  React.useEffect(() => {
    connectWebSocket();

    return () => socket?.close();
  }, [connectWebSocket]);

  const sendMessage = React.useCallback(
     async (message, files) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        try {
          let media = [];
          if (files?.length) {
            media = await Promise.allSettled(files.map(({ file }) => handleUpload(file)));
            media = media?.map(({ value: { presignedUrl } }) => {
              return new URL(presignedUrl).origin + new URL(presignedUrl).pathname;
            });
          }

          const textMessage = {
            content: {
              message,
              user_id,
              media
            },
            type: "text",
          };

          socket.send(JSON.stringify(textMessage));

          setMessages((prevMessages) => {
            const meesageToDisplay = {
              sent_by: "user",
              content: message,
              media_files: files,
              timestamp: formatMessageTimestamp(new Date().toISOString()),
            }

            if (Array.isArray(prevMessages)) {
              return [
                ...prevMessages,
                meesageToDisplay
              ]
            }
            return [meesageToDisplay];
          });
        } catch (e) {
          console.error(e)
        }
      }
    },
    [socket, user_id]
  );

  return { messages, sendMessage, eventData, isLoading };
};

export default useMessages;
