import React from 'react';
import { useFetchResult } from './useFetchResult.js';
import { getData } from '../api/index.js';
import { formatMessageTimestamp, generateId } from '../utils/fns.js';
import { useApiEndpointsContext } from '../context/api-endpoints-provider.jsx';
import useUploadFile from './useUploadFile.jsx';

const useMessages = () => {
	const { handleUpload } = useUploadFile();
	const {
		data: { user_id, auth_frontend_token },
	} = useApiEndpointsContext();
	const { fetchData: getChatHistory } = useFetchResult(() =>
		getData(`chat-history/chat-history?user_id=${user_id}`, null, true)
	);
	const [isLoading, setIsLoading] = React.useState(true);

	const mapMedia = (mediaUrls) =>
		mediaUrls?.map((fileSrc) => ({
			id: generateId(),
			src: fileSrc,
		}));

	React.useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				let res = await getChatHistory();
				if (res?.length) {
					res = res?.map((item) => {
						return {
							...item,
							content: item?.text_content,
							timestamp: formatMessageTimestamp(item.timestamp),
							media_files: mapMedia(item.media),
						};
					});

					setMessages(res);

					const lastItem = res?.[res.length - 1];
					const creatingContext = lastItem?.entity_details;

					if (creatingContext) {
						if (creatingContext.media_files?.length) {
							creatingContext.media_files = mapMedia(
								creatingContext.media_files
							);
						}
						// if (lastItem?.action_summary) {
						// 	creatingContext.action_summary = lastItem?.action_summary;
						// }
						// if (lastItem?.action_status) {
						// 	creatingContext.action_status = lastItem?.action_status;
						// }

						setEventData(creatingContext);
					}
				}
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const [eventData, setEventData] = React.useState(null);
	const [messages, setMessages] = React.useState(null);
	const [socket, setSocket] = React.useState(null);

	const connectWebSocket = React.useCallback(() => {
		console.log('Connecting to WebSocket...');
		const socketInstance = new WebSocket('wss://chat.activezero.org/api/v1/ws');

		socketInstance.onopen = () => {
			console.log('WebSocket connected');
			// const token =
			// 	'token'; //added field

			const token = auth_frontend_token;

			const connectionMessage = {
				content: { user_id },
				type: 'connect',
				Authorization: `Bearer ${token}`, //added field
			};
			socketInstance.send(JSON.stringify(connectionMessage));
		};

		socketInstance.onmessage = (event) => {
			try {
				console.log(event, 'socket event');
				console.log(JSON.parse(event.data), 'socket event.data response');

				const response = JSON.parse(event.data);

				if (response?.response_content) {
					setMessages((prevMessages) => {
						const meesageToDisplay = {
							...response,
							sent_by: 'AI',
							content: response.response_content,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}
						return [meesageToDisplay];
					});
				}

				const creatingContext = response?.entity_details;

				if (creatingContext) {
					if (creatingContext.media_files?.length) {
						creatingContext.media_files = mapMedia(creatingContext.media_files);
					}
					// if (response?.action_summary) {
					// 	creatingContext.action_summary = response?.action_summary;
					// }
					// if (response?.action_status) {
					// 	creatingContext.action_status = response?.action_status;
					// }

					setEventData(creatingContext);
				}
			} catch (error) {
				console.error('Error parsing message:', error);
			}
		};

		socketInstance.onclose = () => {
			console.log('WebSocket disconnected');
		};

		socketInstance.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		setSocket(socketInstance);

		return () => socketInstance.close();
	}, [user_id]);

	React.useEffect(() => {
		connectWebSocket();

		return () => socket?.close();
	}, [connectWebSocket]);

	const sendMessage = React.useCallback(
		async (message, files) => {
			if (socket && socket.readyState === WebSocket.OPEN) {
				try {
					let media = [];
					if (files?.length) {
						media = await Promise.allSettled(
							files.map(({ file }) => handleUpload(file))
						);
						media = media?.map(({ value: { presignedUrl } }) => {
							return (
								new URL(presignedUrl).origin + new URL(presignedUrl).pathname
							);
						});
					}

					const isValidString = (str) => {
						const regex =
							/^(?=(?:\s*\w+){1,4}\s*$).*?\b(?:sav(?:e|es|ed|ing))\b.*$/i;
						return regex.test(str);
					};

					const textMessage = {
						content: {
							message,
							user_id,
							media,
						},
						type: 'text',
					};

					const textMessageSave = {
						content: {
							message,
							user_id,
							media,
							desired_step: 'complete_creation',
						},
						type: 'text',
					};

					socket.send(
						JSON.stringify(
							isValidString(message) ? textMessageSave : textMessage
						)
					);
					console.log(
						JSON.stringify(
							isValidString(message) ? textMessageSave : textMessage
						),
						'send data'
					);

					setMessages((prevMessages) => {
						const meesageToDisplay = {
							sent_by: 'user',
							content: message,
							media_files: files,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}
						return [meesageToDisplay];
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
		[socket, user_id]
	);

	const sendSaveMemory = React.useCallback(
		async (message, files) => {
			if (socket && socket.readyState === WebSocket.OPEN) {
				try {
					let media = [];
					if (files?.length) {
						media = await Promise.allSettled(
							files.map(({ file }) => handleUpload(file))
						);
						media = media?.map(({ value: { presignedUrl } }) => {
							return (
								new URL(presignedUrl).origin + new URL(presignedUrl).pathname
							);
						});
					}

					const textMessage = {
						content: {
							message,
							user_id,
							media,
							desired_step: 'complete_creation',
						},
						type: 'text',
					};

					socket.send(JSON.stringify(textMessage));
					console.log(JSON.stringify(textMessage), 'save memory');
					setMessages((prevMessages) => {
						const meesageToDisplay = {
							sent_by: 'user',
							content: message,
							media_files: files,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}
						return [meesageToDisplay];
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
		[socket, user_id]
	);

	return { messages, sendMessage, eventData, isLoading, sendSaveMemory };
};

export default useMessages;
