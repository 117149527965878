import React from 'react';
import { useFetchResult } from './useFetchResult.js';
import { getData } from '../api/index.js';
import { formatMessageTimestamp, generateId } from '../utils/fns.js';
import { useApiEndpointsContext } from '../context/api-endpoints-provider.jsx';
import useUploadFile from './useUploadFile.jsx';

const useMessages = () => {
	const { handleUpload } = useUploadFile();
	const {
		data: { user_id, auth_frontend_token },
	} = useApiEndpointsContext();

	const { fetchData: getChatHistory } = useFetchResult(() =>
		getData(`chat-history/messages/${user_id}`, null, true)
	);
	const [isLoading, setIsLoading] = React.useState(true);

	const mapMedia = (mediaUrls) =>
		mediaUrls?.map((fileSrc) => ({
			id: generateId(),
			src: fileSrc,
		}));

	React.useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				let res = await getChatHistory();
				console.log(res, 'res');

				if (res?.messages?.length) {
					const updatedMessages = res.messages.map((item) => ({
						...item,
						content: item?.message,
						timestamp: formatMessageTimestamp(item.timestamp),
						media_files: item.media?.files.length
							? mapMedia(item.media.files)
							: null,
					}));

					setMessages(updatedMessages);

					const lastItem = updatedMessages?.[updatedMessages.length - 1];
					const creatingContext = lastItem?.entity_details;

					if (creatingContext) {
						if (creatingContext.media_files?.length) {
							creatingContext.media_files = mapMedia(
								creatingContext.media_files
							);
						}

						setEventData(creatingContext);
					}
				}
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const [eventData, setEventData] = React.useState(null);
	const [messages, setMessages] = React.useState(null);
	const [socket, setSocket] = React.useState(null);
	const [socketError, setSocketError] = React.useState(false);
	const [flowId, setFlowId] = React.useState(null);
	const [completeStatus, setCompleteStatus] = React.useState(false);
	const [abortFlow, setAbortFlow] = React.useState(false);

	const connectWebSocket = React.useCallback(() => {
		console.log('Connecting to WebSocket...');
		const socketInstance = new WebSocket('wss://chat.activezero.org/api/v1/ws');

		socketInstance.onopen = () => {
			console.log('WebSocket connected');
			// const token =
			// 	'token'; //added field

			const token = auth_frontend_token;

			const connectionMessage = {
				content: { user_id },
				type: 'connect',
				Authorization: `Bearer ${token}`, //added field
			};
			socketInstance.send(JSON.stringify(connectionMessage));
		};

		socketInstance.onmessage = (event) => {
			try {
				console.log(event, 'socket event');
				console.log(JSON.parse(event.data), 'socket event.data response');

				const response = JSON.parse(event.data);

				if (response?.flow_id) {
					setFlowId(response.flow_id);

					const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));

					if (chatFlowId) {
						localStorage.removeItem('flow_id');
						localStorage.setItem('flow_id', JSON.stringify(response.flow_id));
					} else {
						localStorage.setItem('flow_id', JSON.stringify(response.flow_id));
					}
				}

				if (response?.active_entity) {
					console.log(response.active_entity, 'response.active_entity');

					const chatEntity = localStorage.getItem('active_entity');

					if (chatEntity) {
						localStorage.removeItem('active_entity');
						localStorage.setItem('active_entity', response.active_entity);
					} else {
						localStorage.setItem('active_entity', response.active_entity);
					}
				}

				if (response?.message && !response?.error) {
					setMessages((prevMessages) => {
						const meesageToDisplay = {
							...response,
							sent_by: 'AI',
							content: response.message,
							// content: response.content,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}

						return [meesageToDisplay];
					});

					if (response?.action_status === 'completed') {
						setCompleteStatus(true);
					}

					if (response?.action_status === 'aborted') {
						setAbortFlow(true);
					}
				}

				// if (response?.status === 'undefined_asset') {
				// 	setMessages((prevMessages) => {
				// 		const meesageToDisplay = {
				// 			...response,
				// 			sent_by: 'AI',
				// 			content: response.content,
				// 			timestamp: formatMessageTimestamp(new Date().toISOString()),
				// 		};

				// 		if (Array.isArray(prevMessages)) {
				// 			return [...prevMessages, meesageToDisplay];
				// 		}

				// 		return [meesageToDisplay];
				// 	});
				// }

				if (response?.error) {
					setMessages((prevMessages) => {
						const meesageToDisplay = {
							...response,
							sent_by: 'AI',
							content: response.message,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}

						return [meesageToDisplay];
					});

					console.log('chat error');
					setSocketError(true);
				}

				const creatingContext = response?.entity_details;

				if (creatingContext) {
					if (creatingContext.media_files?.length) {
						creatingContext.media_files = mapMedia(creatingContext.media_files);
					}

					setEventData(creatingContext);
				}
			} catch (error) {
				console.error('Error parsing message:', error);
			}
		};

		socketInstance.onclose = () => {
			console.log('WebSocket disconnected');
		};

		socketInstance.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		setSocket(socketInstance);

		return () => socketInstance.close();
	}, [user_id]);

	React.useEffect(() => {
		connectWebSocket();

		return () => socket?.close();
	}, [connectWebSocket]);

	const sendMessage = React.useCallback(
		async (message, files) => {
			if (socket && socket.readyState === WebSocket.OPEN) {
				try {
					let media = [];
					if (files?.length) {
						media = await Promise.allSettled(
							files.map(({ file }) => handleUpload(file))
						);
						media = media?.map(({ value: { presignedUrl } }) => {
							return (
								new URL(presignedUrl).origin + new URL(presignedUrl).pathname
							);
						});
					}

					const imageExtensions = ['jpeg', 'jpg', 'png', 'gif'];
					const documentExtensions = [
						'pdf',
						'doc',
						'docx',
						'txt',
						'xls',
						'xlsx',
					];

					const processedMedia = media.map((url) => {
						const extension = url.split('.').pop().toLowerCase();

						let type = 'unknown';

						if (imageExtensions.includes(extension)) {
							type = 'image';
						} else if (documentExtensions.includes(extension)) {
							type = 'document';
						}

						return { url, type };
					});

					const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));

					const chatEntity = localStorage.getItem('active_entity');

					console.log(chatEntity, 'active entity');

					const textMessage = {
						content: {
							message,
							user_id,
							// media,
							media: processedMedia,
							flow_id: flowId ? flowId : chatFlowId,
						},
						type: 'text',
					};

					const textMessageAsset = {
						content: {
							message,
							user_id,
							// media,
							media: processedMedia,
							flow_id: flowId ? flowId : chatFlowId,
							active_entity: chatEntity,
						},
						type: 'text',
					};

					socket.send(
						JSON.stringify(chatEntity ? textMessageAsset : textMessage)
					);

					console.log(
						JSON.stringify(chatEntity ? textMessageAsset : textMessage),
						'send data'
					);

					setMessages((prevMessages) => {
						const meesageToDisplay = {
							sent_by: 'user',
							content: message,
							media_files: files,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}
						return [meesageToDisplay];
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
		[socket, user_id]
	);

	const sendSaveMemory = React.useCallback(
		async (message, files) => {
			if (socket && socket.readyState === WebSocket.OPEN) {
				try {
					let media = [];
					if (files?.length) {
						media = await Promise.allSettled(
							files.map(({ file }) => handleUpload(file))
						);
						media = media?.map(({ value: { presignedUrl } }) => {
							return (
								new URL(presignedUrl).origin + new URL(presignedUrl).pathname
							);
						});
					}

					const imageExtensions = ['jpeg', 'jpg', 'png', 'gif'];
					const documentExtensions = [
						'pdf',
						'doc',
						'docx',
						'txt',
						'xls',
						'xlsx',
					];

					const processedMedia = media.map((url) => {
						const extension = url.split('.').pop().toLowerCase();

						let type = 'unknown';

						if (imageExtensions.includes(extension)) {
							type = 'image';
						} else if (documentExtensions.includes(extension)) {
							type = 'document';
						}

						return { url, type };
					});

					const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));

					const chatEntity = localStorage.getItem('active_entity');

					console.log(chatEntity, 'active entity');

					const textMessage = {
						content: {
							message,
							user_id,
							// media,
							media: processedMedia,
							flow_id: flowId ? flowId : chatFlowId,
						},
						type: 'text',
					};

					const textMessageAsset = {
						content: {
							message,
							user_id,
							// media,
							media: processedMedia,
							flow_id: flowId ? flowId : chatFlowId,
							active_entity: chatEntity,
						},
						type: 'text',
					};

					socket.send(
						JSON.stringify(chatEntity ? textMessageAsset : textMessage)
					);

					console.log(
						JSON.stringify(chatEntity ? textMessageAsset : textMessage),
						'send data'
					);

					setMessages((prevMessages) => {
						const meesageToDisplay = {
							sent_by: 'user',
							content: message,
							media_files: files,
							timestamp: formatMessageTimestamp(new Date().toISOString()),
						};

						if (Array.isArray(prevMessages)) {
							return [...prevMessages, meesageToDisplay];
						}
						return [meesageToDisplay];
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
		[socket, user_id]
	);

	React.useEffect(() => {
		if (abortFlow) {
			const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));
			const saveFlow = {
				content: {
					flow_id: flowId ? flowId : chatFlowId,
				},
				type: 'flowDrop',
			};

			if (socket && socket.readyState === WebSocket.OPEN) {
				console.log(JSON.stringify(saveFlow), 'socket flowDrop');
				socket.send(JSON.stringify(saveFlow));

				setFlowId(null);
				localStorage.removeItem('flow_id');
				localStorage.removeItem('active_entity');
				setAbortFlow(false);
			}
		}
	}, [abortFlow]);

	React.useEffect(() => {
		if (completeStatus) {
			const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));
			const saveFlow = {
				content: {
					flow_id: flowId ? flowId : chatFlowId,
				},
				type: 'flowFinish',
			};

			if (socket && socket.readyState === WebSocket.OPEN) {
				if (completeStatus) {
					console.log(JSON.stringify(saveFlow), 'socket flowfinish');
					socket.send(JSON.stringify(saveFlow));

					setFlowId(null);
					localStorage.removeItem('flow_id');
					localStorage.removeItem('active_entity');
					setCompleteStatus(false);
				}
			}
		}
	}, [completeStatus]);

	React.useEffect(() => {
		if (socketError) {
			const chatFlowId = JSON.parse(localStorage.getItem('flow_id'));

			const errorMessage = {
				content: {
					flow_id: flowId ? flowId : chatFlowId,
				},
				type: 'flowFail',
			};

			if (socket && socket.readyState === WebSocket.OPEN) {
				console.log(JSON.stringify(errorMessage), 'socket send error');

				socket.send(JSON.stringify(errorMessage));

				setSocketError(false);
				setFlowId(null);
				localStorage.removeItem('flow_id');
				localStorage.removeItem('active_entity');
			}
		}
	}, [socketError]);

	return { messages, sendMessage, eventData, isLoading, sendSaveMemory };
};

export default useMessages;
