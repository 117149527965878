import { useCallback, useState, createContext, useContext } from "react";
import PropTypes from "prop-types";

export const Context = createContext({
  modals: {},
  addModal: (props) => props,
  closeModal: (props) => props,
});

function ModalsProvider({ children }) {
  const [modals, setModals] = useState(new Map());

  const handleAddModal = useCallback((payload) => {
    setModals((state) => new Map([...Array.from(state), [payload.id, payload]]));
  }, []);

  const handleCloseModal = useCallback((payload) => {
    setModals((state) => new Map([...Array.from(state).filter((x) => x[0] !== payload.id)]));
  }, []);

  return (
    <Context.Provider
      value={{
        modals,
        addModal: handleAddModal,
        closeModal: handleCloseModal,
      }}>
      {children}
    </Context.Provider>
  );
}

export function useModalsContext() {
  const { modals, addModal, closeModal } = useContext(Context);
  return { modals, addModal, closeModal };
}

ModalsProvider.propTypes = {
  children: PropTypes.node,
};

export default ModalsProvider;
