const TotalAmountIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10 5.5H4V6.5H10V5.5Z" fill="#565967" />
			<path d="M10 8H4V9H10V8Z" fill="#565967" />
			<path
				d="M14 2.20705L13.2929 1.5L11.618 3.175C11.3788 2.8866 11.1133 2.62107 10.825 2.38185L12.5 0.70705L11.7929 0L9.9883 1.8047C8.84626 1.14419 7.51809 0.879221 6.21003 1.05093C4.90196 1.22264 3.68717 1.82142 2.75429 2.75429C1.82142 3.68717 1.22264 4.90196 1.05093 6.21003C0.879221 7.51809 1.14419 8.84626 1.8047 9.9883L0 11.7929L0.70705 12.5L2.38205 10.825C2.6212 11.1134 2.88666 11.3789 3.175 11.6181L1.5 13.2929L2.20705 14L4.0117 12.1953C5.15374 12.8558 6.48191 13.1208 7.78998 12.9491C9.09804 12.7774 10.3128 12.1786 11.2457 11.2457C12.1786 10.3128 12.7774 9.09804 12.9491 7.78998C13.1208 6.48191 12.8558 5.15374 12.1953 4.0117L14 2.20705ZM7 12C6.01109 12 5.04439 11.7068 4.22215 11.1573C3.3999 10.6079 2.75904 9.82705 2.3806 8.91342C2.00216 7.99979 1.90315 6.99445 2.09607 6.02455C2.289 5.05464 2.7652 4.16373 3.46447 3.46447C4.16373 2.7652 5.05464 2.289 6.02455 2.09607C6.99445 1.90315 7.99979 2.00216 8.91342 2.3806C9.82705 2.75904 10.6079 3.3999 11.1573 4.22215C11.7068 5.04439 12 6.01109 12 7C11.9985 8.32562 11.4712 9.59651 10.5339 10.5339C9.59651 11.4712 8.32562 11.9985 7 12Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default TotalAmountIcon;
