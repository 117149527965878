export function generateId(prefix = '') {
	return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
}

export function to(promise, ...args) {
	return promise(...args)
		.then((data) => {
			return { err: null, data };
		})
		.catch((err) => ({ err, data: null }));
}

export const resolveError = (error) => {
	if (error.response) {
		const { data } = error.response;
		if (typeof data === 'string') {
			return Promise.reject(data);
		} else if (data?.message || data?.error) {
			return Promise.reject(data?.message || data?.error);
		}
	}
	return Promise.reject('Something went wrong! Please try again later!');
};

export const getPageId = () =>
	Number(window.location.pathname.split('/').pop());

export const formatMessageTimestamp = (timestamp) => {
	const currentTime = new Date();
	const pastTime = new Date(timestamp);
	const differenceInMilliseconds = currentTime - pastTime;

	const seconds = Math.floor(differenceInMilliseconds / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const months = Math.floor(days / 30);

	if (months > 0) {
		return `${months} month(s) ago`;
	} else if (days > 0) {
		return `${days} day(s) ago`;
	} else if (hours > 0) {
		return `${hours} hour(s) ago`;
	} else if (minutes > 0) {
		return `${minutes} minute(s) ago`;
	} else {
		return 'Just now';
	}
};

export const viewEventRedirect = (entity_details) => {
	if (entity_details?.knowledge_id) {
		window.open(`/myself/${entity_details.knowledge_id}`, '_blank');
	}

	if (entity_details?.event_id) {
		window.open(`/lifetime/event/${entity_details.event_id}`, '_blank');
	}

	if (entity_details?.memory_id) {
		window.open(`/lifetime/memory/${entity_details.memory_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.registration_number) {
		window.open(`/assets/car/${entity_details.asset_id}`, '_blank');
	}

	if (
		entity_details?.asset_id &&
		entity_details?.bank_name &&
		Object.keys(entity_details).length !== 14
	) {
		window.open(`/assets/bank/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.exact_address) {
		window.open(`/assets/property-title/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.law_firm) {
		window.open(`/assets/trust/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.location_title) {
		window.open(`/assets/estate/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.item_name) {
		window.open(`/assets/misc-item/${entity_details.asset_id}`, '_blank');
	}

	if (
		entity_details?.asset_id &&
		entity_details?.bank &&
		!entity_details?.share
	) {
		window.open(`/assets/stock/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.asset_id && entity_details?.share) {
		window.open(`/assets/dividend/${entity_details.asset_id}`, '_blank');
	}

	if (
		entity_details?.asset_id &&
		entity_details?.total_amount &&
		Object.keys(entity_details).length !== 14
	) {
		window.open(`/assets/loan/${entity_details.asset_id}`, '_blank');
	}

	if (
		entity_details?.asset_id &&
		entity_details?.total_amount &&
		Object.keys(entity_details).length === 14
	) {
		window.open(`/assets/mortgage/${entity_details.asset_id}`, '_blank');
	}

	if (entity_details?.wish_id) {
		window.open(`/user/wish/${entity_details.wish_id}`, '_blank');
	}
};

export const getSaveText = (entity_details) => {
	if (entity_details) {
		if (entity_details.hasOwnProperty('memory_id')) return 'Save memory';
		if (entity_details.hasOwnProperty('event_id')) return 'Save event';
		if (entity_details.hasOwnProperty('asset_id')) return 'Save asset';
		if (entity_details.hasOwnProperty('knowledge_id')) return 'Save knowledge';
		if (entity_details.hasOwnProperty('wish_id')) return 'Save bucket list';
		return 'Save';
	} else {
		return 'Save';
	}
};

export const getPreviewText = (entity_details) => {
	if (entity_details) {
		if (entity_details.hasOwnProperty('memory_id')) return 'Preview Memory';
		if (entity_details.hasOwnProperty('event_id')) return 'Preview Event';
		if (entity_details.hasOwnProperty('asset_id')) return 'Preview Asset';
		if (entity_details.hasOwnProperty('wish_id')) return 'Preview Bucket list';
		if (entity_details.hasOwnProperty('knowledge_id'))
			return 'Preview Knowledge';
		return 'Preview';
	} else {
		return 'Preview';
	}
};
