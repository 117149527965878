const AccountNumberIcon = () => {
	return (
		<svg
			width="14"
			height="12"
			viewBox="0 0 14 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.25 5H9V4H8V3.5H7V4.0254C6.69665 4.08751 6.42714 4.25999 6.24369 4.50945C6.06025 4.7589 5.97591 5.06757 6.00704 5.37564C6.03816 5.68372 6.18252 5.96928 6.41216 6.17701C6.64179 6.38473 6.94035 6.49983 7.25 6.5H7.75C7.8163 6.5 7.87989 6.52634 7.92678 6.57322C7.97366 6.62011 8 6.6837 8 6.75C8 6.8163 7.97366 6.87989 7.92678 6.92678C7.87989 6.97366 7.8163 7 7.75 7H6V8H7V8.5H8V7.9746C8.30335 7.9125 8.57286 7.74001 8.75631 7.49055C8.93976 7.2411 9.02409 6.93243 8.99296 6.62436C8.96184 6.31628 8.81747 6.03072 8.58784 5.823C8.35821 5.61527 8.05965 5.50018 7.75 5.5H7.25C7.1837 5.5 7.12011 5.47366 7.07322 5.42678C7.02634 5.37989 7 5.3163 7 5.25C7 5.1837 7.02634 5.12011 7.07322 5.07322C7.12011 5.02634 7.1837 5 7.25 5Z"
				fill="#565967"
			/>
			<path
				d="M13.5 4.5H12.49C12.4756 4.10501 12.3803 3.71717 12.21 3.36048C12.0397 3.00378 11.7981 2.68582 11.5 2.42625V0.5C11.5 0.407144 11.4741 0.316123 11.4253 0.237135C11.3765 0.158147 11.3067 0.094313 11.2236 0.0527866C11.1406 0.0112601 11.0476 -0.0063184 10.9551 0.00202058C10.8626 0.0103596 10.7743 0.0442865 10.7 0.1L8.83325 1.5H6.5C3.74525 1.5 1.7683 3.1206 1.52575 5.5H1.5C1.36743 5.49988 1.24032 5.44716 1.14658 5.35342C1.05284 5.25968 1.00012 5.13257 1 5V4H0V5C0.00043674 5.39769 0.158612 5.77897 0.439822 6.06018C0.721032 6.34139 1.10231 6.49956 1.5 6.5H1.535C1.60755 7.1556 1.82065 7.78783 2.15975 8.35358C2.49886 8.91933 2.95601 9.40529 3.5 9.7783V11.5C3.5 11.6326 3.55268 11.7598 3.64645 11.8536C3.74021 11.9473 3.86739 12 4 12H6C6.13261 12 6.25979 11.9473 6.35355 11.8536C6.44732 11.7598 6.5 11.6326 6.5 11.5V10.5H8V11.5C8 11.6326 8.05268 11.7598 8.14645 11.8536C8.24021 11.9473 8.36739 12 8.5 12H10.5C10.6326 12 10.7598 11.9473 10.8536 11.8536C10.9473 11.7598 11 11.6326 11 11.5V9.8186C11.3612 9.64736 11.677 9.39351 11.9218 9.07757C12.1667 8.76163 12.3337 8.39248 12.4094 8H13.5C13.6326 8 13.7598 7.94732 13.8536 7.85355C13.9473 7.75979 14 7.63261 14 7.5V5C14 4.86739 13.9473 4.74021 13.8536 4.64645C13.7598 4.55268 13.6326 4.5 13.5 4.5ZM13 7H11.562C11.4094 8.37645 11.1502 8.7427 10 9.1577V11H9V9.5H5.5V11H4.5V9.18895C3.89796 8.90253 3.39005 8.45041 3.03582 7.8856C2.68159 7.32078 2.49572 6.66669 2.5 6C2.5 3.5823 4.50905 2.5 6.5 2.5H9.16675L10.5 1.5V2.8882C11.7091 3.81785 11.4563 4.4812 11.5091 5.5H13V7Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default AccountNumberIcon;
