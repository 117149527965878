import TitleIcon from '@chat/screens/Chat/components/MainSection/parts/icons/TitleIcon';
import DescriptionIcon from '@chat/screens/Chat/components/MainSection/parts/icons/DescriptionIcon';
import CategoryIcon from '@chat/screens/Chat/components/MainSection/parts/icons/CategoryIcon';
import TimelineIcon from '@chat/screens/Chat/components/MainSection/parts/icons/TimelineIcon';
import LocationIcon from '@chat/screens/Chat/components/MainSection/parts/icons/LocationIcon';
import MediaIcon from '@chat/screens/Chat/components/MainSection/parts/icons/MediaIcon';
import TextBlockIcon from '@chat/screens/Chat/components/MainSection/parts/icons/TextBlockIcon';
import UploadPhotosIcon from '@chat/components/icons/ai-upload-photos.jsx';
import ModelBrandIcon from '@chat/components/icons/ai-model-brand-icon.jsx';
import WorthIcon from '@chat/components/icons/ai-worth-icon.jsx';
import InheritorsIcon from '@chat/components/icons/ai-inheritors-icon.jsx';
import AccountNumberIcon from '@chat/components/icons/ai-account-number-icon.jsx';
import BankNameIcon from '@chat/components/icons/ai-bank-name-icon.jsx';
import RemainingBalanceIcon from '@chat/components/icons/ai-remaining-balance-icon.jsx';
import TotalAmountIcon from '@chat/components/icons/ai-total-amount-icon.jsx';
import AddressIcon from '@chat/components/icons/ai-address-icon.jsx';
import MonthlyRateIcon from '@chat/components/icons/ai-monthly-rate-icon.jsx';
import PaidIcon from '@chat/components/icons/ai-paid-icon.jsx';
import UploadDocumentsIcon from '@chat/components/icons/ai-upload-documents-icon.jsx';
import AdditionalInfoIcon from '@chat/components/icons/ai-additional-info-icon.jsx';
import EstateTypeIcon from '@chat/components/icons/ai-estate-type-icon.jsx';
import ShareIcon from '@chat/components/icons/ai-share-icon.jsx';
import OtherPeopleIcon from '@chat/components/icons/ai-other-people-icon.jsx';
import HardcopyLocation from '@chat/components/icons/ai-hardcopy-location-icon.jsx';

export const API_URL_MEMORY = 'https://memory-api.yourstruly.love';
export const API_URL_NEW = 'https://newstaging.yourstruly.love';

export const MODALS_ID = {
	VIEW_IMAGE_MODAL: 'VIEW_IMAGE_MODAL',
	ASSET_ADD_INHERITAGE: 'ASSET_ADD_INHERITAGE',
};

export const EVENTS = {
	MEMORIES: 'Memories',
	KNOWLEDGE: 'Knowledge',
	ASSETS: 'Assets',
	WISHES: 'Wishes',
	FUNERAL: 'Funeral',
};

export const MEDIA_TYPES = {
	VIDEO: 'Video',
	AUDIO: 'Audio',
	IMAGES: 'Images',
	DOCUMENTS: 'Documents',
};

export const LAYOUT_TYPES = {
	ROW: 'ROW',
	COLUMN: 'COLUMN',
};

export const STATUS_ENUM = {
	COLLECTIND_DATA: 'collecting_data',
	AWAITING_APPROVAL: 'awaiting_confirmation',
	COMPLETED: 'completed',
};

export const REQUIRED_FIELDS_MAP = {
	title: {
		label: 'Add title',
		icon: <TitleIcon />,
	},
	short_description: {
		label: 'Add short description',
		icon: <DescriptionIcon />,
	},
	summary: {
		label: 'Add summary',
		icon: <DescriptionIcon />,
	},
	categories: {
		label: 'Add categories',
		icon: <CategoryIcon />,
	},
	date: {
		label: 'Add time',
		icon: <TimelineIcon />,
	},
	start_date: {
		label: 'Add timeline',
		icon: <TimelineIcon />,
	},
	location: {
		label: 'Add location',
		icon: <LocationIcon />,
	},
	tags: {
		label: 'Add tags',
		icon: <CategoryIcon />,
	},
	media_files: {
		label: 'Add media files',
		icon: <MediaIcon />,
	},
	media: {
		label: 'Add media files',
		icon: <MediaIcon />,
	},
	cover: {
		label: 'Add cover image',
		icon: <MediaIcon />,
	},
	text_blocks: {
		label: 'Add text block',
		icon: <TextBlockIcon />,
	},
	additional_info: {
		label: 'Add additional info',
		icon: <AdditionalInfoIcon />,
	},
	description: {
		label: 'Add description',
		icon: <AdditionalInfoIcon />,
	},
	documents: {
		label: 'Upload documents',
		icon: <UploadDocumentsIcon />,
	},
	inheritors: {
		label: 'Add Inheritors',
		icon: <InheritorsIcon />,
	},
	make_model: {
		label: 'Add Model/ brand',
		icon: <ModelBrandIcon />,
	},
	photos: {
		label: 'Upload photos',
		icon: <UploadPhotosIcon />,
	},
	worth: {
		label: 'Add worth',
		icon: <WorthIcon />,
	},
	account_type: {
		label: 'Add Account type',
		icon: <TextBlockIcon />,
	},
	account_number: {
		label: 'Add Account number',
		icon: <AccountNumberIcon />,
	},
	balance: {
		label: 'Add Balance',
		icon: <RemainingBalanceIcon />,
	},
	bank_name: {
		label: 'Add Bank name',
		icon: <BankNameIcon />,
	},
	country: {
		label: 'Add Country',
		icon: <AddressIcon />,
	},
	exact_address: {
		label: 'Add Exact address',
		icon: <AddressIcon />,
	},
	net_worth: {
		label: 'Add Net worth',
		icon: <WorthIcon />,
	},
	other_people: {
		label: 'Add Other people',
		icon: <OtherPeopleIcon />,
	},
	title_location: {
		label: 'Add Title location',
		icon: <TitleIcon />,
	},
	location_title: {
		label: 'Add Title location',
		icon: <TitleIcon />,
	},
	address: {
		label: 'Add address',
		icon: <AddressIcon />,
	},
	bank: {
		label: 'Add bank',
		icon: <BankNameIcon />,
	},
	name: {
		label: 'Add name',
		icon: <BankNameIcon />,
	},
	accounts: {
		label: 'Add accounts',
		icon: <AccountNumberIcon />,
	},
	share: {
		label: 'Add share',
		icon: <ShareIcon />,
	},
	company_name: {
		label: 'Add Company name',
		icon: <TitleIcon />,
	},
	item_name: {
		label: 'Add Item name',
		icon: <TitleIcon />,
	},
	hard_copy_location: {
		label: 'Add Hard copy location',
		icon: <HardcopyLocation />,
	},
	law_firm: {
		label: 'Add Law firm',
		icon: <RemainingBalanceIcon />,
	},
	monthly_rate: {
		label: 'Add Monthly rate',
		icon: <MonthlyRateIcon />,
	},
	paid: {
		label: 'Add Paid',
		icon: <PaidIcon />,
	},
	remaining_balance: {
		label: 'Add Remaining balance',
		icon: <RemainingBalanceIcon />,
	},
	remaining_time: {
		label: 'Add Remaining time',
		icon: <RemainingBalanceIcon />,
	},
	total_amount: {
		label: 'Add Total amount',
		icon: <TotalAmountIcon />,
	},
	estate_address: {
		label: 'Add Estate address',
		icon: <AddressIcon />,
	},
	estate_type: {
		label: 'Add Estate type',
		icon: <EstateTypeIcon />,
	},
	registration_number: {
		label: 'Add Registration number',
		icon: <AdditionalInfoIcon />,
	},
};

export const UPDATED_FIELDS_MAP = {
	title: {
		label: 'Change title',
		icon: <TitleIcon />,
	},
	short_description: {
		label: 'Change short description',
		icon: <DescriptionIcon />,
	},
	summary: {
		label: 'Change summary',
		icon: <DescriptionIcon />,
	},
	categories: {
		label: 'Change categories',
		icon: <CategoryIcon />,
	},
	date: {
		label: 'Change time',
		icon: <TimelineIcon />,
	},
	start_date: {
		label: 'Change timeline',
		icon: <TimelineIcon />,
	},
	location: {
		label: 'Change location',
		icon: <LocationIcon />,
	},
	tags: {
		label: 'Add/remove tags',
		icon: <CategoryIcon />,
	},
	media_files: {
		label: 'Add media files',
		icon: <MediaIcon />,
	},
	media: {
		label: 'Add media files',
		icon: <MediaIcon />,
	},
	text_blocks: {
		label: 'Add text block',
		icon: <TextBlockIcon />,
	},
	additional_info: {
		label: 'Change additional info',
		icon: <AdditionalInfoIcon />,
	},
	description: {
		label: 'Change description',
		icon: <AdditionalInfoIcon />,
	},
	documents: {
		label: 'Add new documents',
		icon: <UploadDocumentsIcon />,
	},
	inheritors: {
		label: 'Add inheritors',
		icon: <InheritorsIcon />,
	},
	make_model: {
		label: 'Change Model/brand',
		icon: <ModelBrandIcon />,
	},
	photos: {
		label: 'Add new photos',
		icon: <UploadPhotosIcon />,
	},
	worth: {
		label: 'Change worth',
		icon: <WorthIcon />,
	},
	account_type: {
		label: 'Change Account type',
		icon: <TextBlockIcon />,
	},
	account_number: {
		label: 'Change Account number',
		icon: <AccountNumberIcon />,
	},
	balance: {
		label: 'Change Balance',
		icon: <RemainingBalanceIcon />,
	},
	bank_name: {
		label: 'Change Bank name',
		icon: <BankNameIcon />,
	},
	country: {
		label: 'Change Country',
		icon: <AddressIcon />,
	},
	exact_address: {
		label: 'Change Exact address',
		icon: <AddressIcon />,
	},
	net_worth: {
		label: 'Change Net worth',
		icon: <WorthIcon />,
	},
	other_people: {
		label: 'Add Other people',
		icon: <OtherPeopleIcon />,
	},
	title_location: {
		label: 'Change Title location',
		icon: <TitleIcon />,
	},
	location_title: {
		label: 'Change Title location',
		icon: <TitleIcon />,
	},
	address: {
		label: 'Change address',
		icon: <AddressIcon />,
	},
	bank: {
		label: 'Change bank',
		icon: <BankNameIcon />,
	},
	name: {
		label: 'Change name',
		icon: <BankNameIcon />,
	},
	accounts: {
		label: 'Change accounts',
		icon: <AccountNumberIcon />,
	},
	share: {
		label: 'Change share',
		icon: <ShareIcon />,
	},
	company_name: {
		label: 'Change Company name',
		icon: <TitleIcon />,
	},
	item_name: {
		label: 'Change Item name',
		icon: <TitleIcon />,
	},
	hard_copy_location: {
		label: 'Change Hard copy location',
		icon: <HardcopyLocation />,
	},
	law_firm: {
		label: 'Change Law firm',
		icon: <RemainingBalanceIcon />,
	},
	monthly_rate: {
		label: 'Change Monthly rate',
		icon: <MonthlyRateIcon />,
	},
	paid: {
		label: 'Change Paid',
		icon: <PaidIcon />,
	},
	remaining_balance: {
		label: 'Change Remaining balance',
		icon: <RemainingBalanceIcon />,
	},
	remaining_time: {
		label: 'Change Remaining time',
		icon: <RemainingBalanceIcon />,
	},
	total_amount: {
		label: 'Change Total amount',
		icon: <TotalAmountIcon />,
	},
	estate_address: {
		label: 'Change Estate address',
		icon: <AddressIcon />,
	},
	estate_type: {
		label: 'Change Estate type',
		icon: <EstateTypeIcon />,
	},
	cover: {
		label: 'Change cover image',
		icon: <MediaIcon />,
	},
	registration_number: {
		label: 'Change Registration number',
		icon: <AdditionalInfoIcon />,
	},
};
