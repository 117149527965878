const WorthIcon = () => {
	return (
		<svg
			width="14"
			height="12"
			viewBox="0 0 14 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M14 9H0V10H14V9Z" fill="#565967" />
			<path d="M14 11H0V12H14V11Z" fill="#565967" />
			<path
				d="M11 3C10.8022 3 10.6089 3.05865 10.4444 3.16853C10.28 3.27841 10.1518 3.43459 10.0761 3.61732C10.0004 3.80004 9.98063 4.00111 10.0192 4.19509C10.0578 4.38907 10.153 4.56725 10.2929 4.70711C10.4327 4.84696 10.6109 4.9422 10.8049 4.98079C10.9989 5.01937 11.2 4.99957 11.3827 4.92388C11.5654 4.84819 11.7216 4.72002 11.8315 4.55557C11.9414 4.39112 12 4.19778 12 4C12 3.73478 11.8946 3.48043 11.7071 3.29289C11.5196 3.10536 11.2652 3 11 3Z"
				fill="#565967"
			/>
			<path
				d="M7 6C6.60444 6 6.21776 5.8827 5.88886 5.66294C5.55996 5.44318 5.30362 5.13082 5.15224 4.76537C5.00087 4.39991 4.96126 3.99778 5.03843 3.60982C5.1156 3.22186 5.30608 2.86549 5.58579 2.58579C5.86549 2.30608 6.22186 2.1156 6.60982 2.03843C6.99778 1.96126 7.39991 2.00087 7.76537 2.15224C8.13082 2.30362 8.44318 2.55996 8.66294 2.88886C8.8827 3.21776 9 3.60444 9 4C8.9994 4.53025 8.7885 5.03861 8.41356 5.41356C8.03861 5.7885 7.53025 5.9994 7 6ZM7 3C6.80222 3 6.60888 3.05865 6.44443 3.16853C6.27998 3.27841 6.15181 3.43459 6.07612 3.61732C6.00043 3.80004 5.98063 4.00111 6.01921 4.19509C6.0578 4.38907 6.15304 4.56725 6.29289 4.70711C6.43275 4.84696 6.61093 4.9422 6.80491 4.98079C6.99889 5.01937 7.19996 4.99957 7.38268 4.92388C7.56541 4.84819 7.72159 4.72002 7.83147 4.55557C7.94135 4.39112 8 4.19778 8 4C7.99974 3.73486 7.89429 3.48066 7.70681 3.29319C7.51934 3.10571 7.26514 3.00026 7 3Z"
				fill="#565967"
			/>
			<path
				d="M3 3C2.80222 3 2.60888 3.05865 2.44443 3.16853C2.27998 3.27841 2.15181 3.43459 2.07612 3.61732C2.00043 3.80004 1.98063 4.00111 2.01921 4.19509C2.0578 4.38907 2.15304 4.56725 2.29289 4.70711C2.43275 4.84696 2.61093 4.9422 2.80491 4.98079C2.99889 5.01937 3.19996 4.99957 3.38268 4.92388C3.56541 4.84819 3.72159 4.72002 3.83147 4.55557C3.94135 4.39112 4 4.19778 4 4C4 3.73478 3.89464 3.48043 3.70711 3.29289C3.51957 3.10536 3.26522 3 3 3Z"
				fill="#565967"
			/>
			<path
				d="M13 8H1C0.73499 7.99933 0.481028 7.89375 0.293637 7.70636C0.106246 7.51897 0.000673203 7.26501 0 7V1C0.000673203 0.73499 0.106246 0.481027 0.293637 0.293637C0.481028 0.106246 0.73499 0.000673203 1 0H13C13.265 0.000673203 13.519 0.106246 13.7064 0.293637C13.8938 0.481027 13.9993 0.73499 14 1V7C13.9996 7.26511 13.8942 7.51925 13.7067 7.70671C13.5193 7.89417 13.2651 7.99964 13 8ZM13 1H1V7H13V1Z"
				fill="#565967"
			/>
		</svg>
	);
};

export default WorthIcon;
