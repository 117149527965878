const UploadPhotosIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.5 6C8.79667 6 9.08668 5.91203 9.33335 5.7472C9.58003 5.58238 9.77229 5.34811 9.88582 5.07403C9.99935 4.79994 10.0291 4.49834 9.97118 4.20736C9.9133 3.91639 9.77044 3.64912 9.56066 3.43934C9.35088 3.22956 9.08361 3.0867 8.79264 3.02882C8.50166 2.97094 8.20006 3.00065 7.92597 3.11418C7.65189 3.22771 7.41762 3.41997 7.2528 3.66664C7.08797 3.91332 7 4.20333 7 4.5C7.00044 4.89769 7.15861 5.27897 7.43982 5.56018C7.72103 5.84139 8.10231 5.99956 8.5 6ZM8.5 4C8.59889 4 8.69556 4.02932 8.77779 4.08427C8.86001 4.13921 8.9241 4.2173 8.96194 4.30866C8.99978 4.40002 9.00969 4.50055 8.99039 4.59755C8.9711 4.69454 8.92348 4.78363 8.85355 4.85355C8.78363 4.92348 8.69454 4.9711 8.59755 4.99039C8.50056 5.00969 8.40002 4.99978 8.30866 4.96194C8.2173 4.9241 8.13921 4.86001 8.08426 4.77779C8.02932 4.69556 8 4.59889 8 4.5C8.00012 4.36743 8.05284 4.24032 8.14658 4.14658C8.24032 4.05284 8.36743 4.00012 8.5 4Z"
				fill="#565967"
			/>
			<path
				d="M7 12H2V8.9983L4.5 6.5L7.29295 9.29295L8 8.584L5.20705 5.79125C5.01952 5.60375 4.76519 5.49841 4.5 5.49841C4.23481 5.49841 3.98048 5.60375 3.79295 5.79125L2 7.584V2H12V7H13V2C12.9997 1.73488 12.8942 1.4807 12.7068 1.29323C12.5193 1.10576 12.2651 1.0003 12 1H2C1.73486 1.00026 1.48066 1.10571 1.29319 1.29319C1.10571 1.48066 1.00026 1.73486 1 2V12C1.0003 12.2651 1.10576 12.5193 1.29323 12.7068C1.4807 12.8942 1.73488 12.9997 2 13H7V12Z"
				fill="#565967"
			/>
			<path d="M15 12H13V10H12V12H10V13H12V15H13V13H15V12Z" fill="#565967" />
		</svg>
	);
};

export default UploadPhotosIcon;
